// import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Row, Col, CardTitle, Form, Label, Input, Button, CardText, FormFeedback } from 'reactstrap'
import { ChevronLeft } from 'react-feather'
// import { AuthContext } from "../../context/AuthContext";
import BlankLayout from '../../@core/layouts/BlankLayout'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AuthApi } from "../../api/authApi";
import source from "../../assets/images/illustration/auth-register-illustration-light.png"
import { NotificationManager } from 'react-notifications';


const schema = yup.object({
    email: yup.string().email('Entrez un mail valide').required('Entrez un email'),
}).required();


const ForgetMdp = () => {

    const { register, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const { ref, ...emailfield } = register("email")

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [email, setEmail] = useState(false)

    const navigate = useNavigate();

    const handleClick = async (data) => {

        setLoading(true)

        const res = await AuthApi.forgotpsw(data)

        if (res.success) {
            NotificationManager.success("Nous avons envoyé un lien à votre adresse e-mail", '', 3000)
            setEmail(data.email)
        }
        else {
            NotificationManager.error("Vérifier votre connexion puis Réessayer", '', 3000)
        }

        setLoading(false)

    };


    return (

        <BlankLayout>

            <div className='auth-wrapper auth-cover'>

                <Row className='auth-inner m-0'>

                    {/* <Link className='brand-logo' to='/' onClick={e => e.preventDefault()}>
                </Link> */}

                    <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
                        <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
                            <img className='tw-w-[50%] tw-h-[80%] ]' src={source} alt='Login Cover' />
                        </div>
                    </Col>

                    <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>

                        <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>

                            {!email ?

                                <>
                                    <CardTitle tag='h2' className='fw-bold mb-1'>
                                        Mot de passe oublié? 🔒
                                    </CardTitle>
                                    <CardText className='mb-2'>
                                        Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe
                                    </CardText>
                                    <Form className='auth-forgot-password-form mt-2' onSubmit={handleSubmit(handleClick)} >
                                        <div className='mb-1'>
                                            <Label className='form-label' for='login-email'>
                                                Email
                                            </Label>
                                            <Input type='text' id='username' innerRef={ref} {...emailfield} invalid={errors?.email} name='email' placeholder='john@mylocker.com' />
                                            {errors?.email &&
                                                <FormFeedback>{errors.email?.message}</FormFeedback>
                                            }
                                        </div>
                                        <Button disabled={loading} color='primary' block>
                                            {loading ? "Chargement..." : "Envoyer le lien"}
                                        </Button>
                                    </Form>
                                    <p className='text-center mt-2'>
                                        <Link to='/login'>
                                            <span className='align-middle'>Retour </span>
                                        </Link>
                                    </p>
                                </>

                                :

                                <>

                                    <CardTitle tag='h2' className='fw-bolder mb-1'>
                                        Vérifiez votre e-mail ✉️
                                    </CardTitle>
                                    <CardText className='mb-2'>
                                        Nous avons envoyé un lien à votre adresse e-mail : <span className='fw-bolder'>{email}</span> Please
                                        Veuillez suivre le lien à l'intérieur pour continuer.
                                    </CardText>
                                    <Button disabled={loading} onClick={() => setEmail(false)} block color='primary'>
                                        {loading ? "Chargement..." : "Changer le mail"}
                                    </Button>
                                    <p className='text-center mt-2'>
                                        <span>Vous n'avez pas recu le email? </span>
                                        <a href='/' onClick={e => { e.preventDefault(); handleClick({ email: email }) }}>
                                            <span>Renvoyer</span>
                                        </a>
                                    </p>

                                </>

                            }

                        </Col>

                    </Col>

                </Row>

            </div>

        </BlankLayout>

    );

};

export default ForgetMdp;
