import { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from 'react-notifications';
import "./new.scss";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import { CMSApi } from "../../api/cmsApi";
import Select from 'react-select';

import PaidIcon from '@mui/icons-material/Paid';


// ** Reactstrap Imports
import { Row, Col } from 'reactstrap'

// ** Custom Components
import StatsHorizontal from '../../@core/components/widgets/stats/StatsHorizontal'
import DoorBackIcon from '@mui/icons-material/DoorBack';

// ** Icons Imports
import moment from 'moment';
import DialogReservation from "../../components/dialog/dialogReservation";
import "react-datepicker/dist/react-datepicker.css";
import {
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap'
import axios from "axios";


const ViewEvent = () => {

  let { id } = useParams();
//   const {state} = useLocation()
  const { data } = useFetch(`/events/${id}`) || [];

  const [info, setInfo] = useState("loading");
  const [lockerstats, setLockerstats] = useState("loading");
  const [eventstats, setEventstats] = useState("loading");
  const [incomePerServicePerDate, setIncomePerServicePerDate] = useState([]);
  const serviceStock =[];

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [dateService, setDateService] = useState(moment().format("YYYY-MM-DD"));
  const [show, setShow] = useState(false);
  const [containers, setContainers] = useState(null)
  const [selectedContainer, setSelectedContainer] = useState(null);

  const [availableStock, setAvailableStock] = useState(null);

  const [initialStock, setInitialStock] = useState(null);

  const [showIncomePerService, setshowIncomePerService] = useState(false);

  if (!Array.isArray(data)) {
    if(data.servicesStock) {
        Object.keys(data.servicesStock).map((key) => {
            data.servicesStock[key].map((item) => {
                
                if(!serviceStock.includes(item)) {
                    serviceStock.push(item)
                }
            })
        })
        
    }
  }
  const GetLockerStats = async() => {

    const res = await CMSApi.getLockerStats(id)

    if (res.success) 
    {
        setLockerstats(res.data)
    }
    else
    {
      NotificationManager.error(`${res.message}`, '', 3000)
    }

  }

  const GetEventStats = async() => {

    const res = await CMSApi.getEventStats(id)

    if (res.success) 
    {
        setEventstats(res.data.data)
        if(res.data.data.servicesIncomePerDate) {
            setIncomePerServicePerDate(res.data.data.servicesIncomePerDate)
            Object.keys(res.data.data.servicesIncomePerDate).map((key) => {
                Object.keys(res.data.data.servicesIncomePerDate[key]).map((item) => {
                    
                    setshowIncomePerService(true)
                })
            })
            
        }
        
    }
    else
    {
      NotificationManager.error(`${res.message}`, '', 3000)
    }

  }

  const handleContainer = (container_name) => {
    if(container_name === 'all') {
        setSelectedContainer(null)
    }else {
        const container = lockerstats.stockByContainer.find((item) => item.name === container_name)
        setSelectedContainer(container)

    }
  }


  useEffect(() => {
    if (data.id) {
      setInfo(data);
    }
  }, [data]);

  useEffect(() => {
    GetLockerStats()
    GetEventStats()
  }, []);

    useEffect(() => {

        if(lockerstats.stockByContainer?.length > 1) {
           setContainers(lockerstats.stockByContainer.map((item) => {
              return {
                    label: item.name,
                    value: item.name
              }
           }));
        }
        setAvailableStock(selectedContainer ? selectedContainer.availableStock : lockerstats.availableStock);
        setInitialStock(selectedContainer ? selectedContainer.stock : lockerstats.initialStock);

        
    }, [lockerstats, selectedContainer])

  
const handleResendEmail = async() => {

    try {
        const res = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/events/resend-email?event=${info.event}&organisateur=${info.organizer_id}`)
        NotificationManager.success('Email renvoyé avec succès', 'succès', 5000);
    } catch (error) {
        NotificationManager.error('Une erreur est survenue', 'Erreur', 5000);
    }
}


  if( info === "loading" || lockerstats === "loading" || eventstats==="loading" )
  {
    return(
      <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center" >
        Loading...
      </div>
    )
  }


  return (

    <div className="datatable">

        <div className=" tw-w-full tw-my-3">

            <Breadcrumbs title={info.event} />
                <button onClick={() => handleResendEmail()} className=" tw-h-[35px] tw-px-[14px] tw-text-white tw-rounded-md " >
                    Renvoyer l'invitation à l'organisateur
                </button>


            <div className=" tw-w-full tw-mt-5 tw-mb-5 tw-flex tw-flex-row tw-items-center tw-justify-between " >
                
                <h3>Détails sur l'évenement</h3>
                
                <button onClick={() => setShow(true)} className=" tw-h-[35px] tw-px-[14px] tw-bg-green-500 tw-text-white tw-rounded-md " >
                    Liste des réservations
                </button>


            </div>

            <div className='app-user-list'>

                <Row>

                    <Col lg='4' sm='6'>
                        <StatsHorizontal
                            color='success'
                            statTitle="CA aujourd'hui"
                            icon={<PaidIcon size={20} />}
                            renderStats={<h3 className='fw-bolder mb-75'>{eventstats.todayReservations} €</h3>}
                        />
                    </Col>

                    <Col lg='4' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='CA Total'
                            icon={<PaidIcon size={20} />}
                            renderStats={<h3 className='fw-bolder mb-75'>{eventstats.payed.totalIncome} €</h3>}
                        />
                    </Col>

                    <Col lg='4' sm='6'>
                        <StatsHorizontal
                            color='danger'
                            statTitle='Annulation'
                            icon={<PaidIcon size={20} />}
                            renderStats={<h3 className='fw-bolder mb-75'>{eventstats.canceled.totalIncome} €</h3>}
                        />
                    </Col>

                </Row>

            </div>


            <div className=" tw-w-full tw-mt-5 tw-mb-3 tw-flex tw-flex-row tw-items-center tw-justify-right tw-gap-4 " >
                    <h4>Sélectionner une date</h4>
                    <input 
                        type="date" value={date} 
                        onChange={(e) => setDate(e.target.value) } 
                        className="tw-w-[300px] tw-h-[40px] tw-px-[4px] tw-rounded-md " 
                    />
            </div>
            <div className='app-user-list'>
                <h3>Chiffre d'affaire par type de locker</h3>
                <Row>
                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers XS'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.xs ?
                                            eventstats.incomePerLockerTypePerDate?.xs?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.xs[date]+' €' : 0+' €'
                                        : ' Indisponible'
                                    }
                                </h3>
                                    
                            }
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers S'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.s ?
                                            eventstats.incomePerLockerTypePerDate?.s?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.s[date]+' €' : 0+' €'
                                        : ' Indisponible'
                                    }
                                </h3>
                                    
                            }
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers L'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.l ?
                                            eventstats.incomePerLockerTypePerDate?.l?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.l[date]+' €' : 0+' €'
                                        : ' Indisponible'
                                    }
                                </h3>
                            } 
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers M'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.m ?
                                            eventstats.incomePerLockerTypePerDate?.m?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.m[date]+' €' : 0+' €'
                                        : ' Indisponible'
                                    }
                                </h3>
                            }  
                        />
                    </Col>

                    
                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers XL'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.xl ?
                                            eventstats.incomePerLockerTypePerDate?.xl?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.xl[date]+' €' : 0+' €'
                                        : ' Indisponible' 
                                    }
                                </h3>
                            }  
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers MB'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        lockerstats.initialStock?.mb ?
                                            eventstats.incomePerLockerTypePerDate?.mb?.hasOwnProperty(date) ? eventstats.incomePerLockerTypePerDate?.mb[date]+' €' : 0
                                        : ' Indisponible' 
                                    }
                                </h3>
                            }
                        />
                    </Col>

                </Row>

            </div>
            {showIncomePerService && <><div className=" tw-w-full tw-mt-5 tw-mb-3 tw-flex tw-flex-row tw-items-center tw-justify-right tw-gap-4 " >
                    <h4>Sélectionner une date</h4>
                    <input 
                        type="date" value={dateService} 
                        onChange={(e) => setDateService(e.target.value) } 
                        className="tw-w-[300px] tw-h-[40px] tw-px-[4px] tw-rounded-md " 
                    />
            </div>
            <div className='app-user-list'>
                <h3>Chiffre d'affaire par service</h3>
                <Row>
                    {
                        
                        Object.keys(incomePerServicePerDate).map((service) => {
                            return (Object.keys(incomePerServicePerDate[service]).map((kpi) => {
                                return (
                                    <Col lg='3' sm='6' key={kpi}>
                                        <StatsHorizontal
                                            color='primary'
                                            statTitle={incomePerServicePerDate[service][kpi].name}
                                            icon={<DoorBackIcon size={20} />}
                                            renderStats={
                                                <h3 className='fw-bolder mb-75'>
                                                    {
                                                        incomePerServicePerDate[service][kpi]?.hasOwnProperty(dateService) ? incomePerServicePerDate[service][kpi][dateService]+' €' : 0+' €'
                                                    }
                                                </h3>
                                            }
                                        />
                                    </Col>
                                );
                            }))
                        })
                    }

                </Row>

            </div>
            </>
            }

            {
                containers && (

                    <div className=" tw-w-full tw-mt-5 tw-mb-3 tw-flex tw-flex-row tw-items-center tw-justify-right tw-gap-4 " >
                        <h4>Sélectionner le conteneur </h4>
                        <Select
                            className="tw-w-[300px] tw-h-[40px] tw-px-[4px] tw-rounded-md "
                            options={ [{ value: 'all', label: 'Tous' }, ...containers]}
                            onChange={(e) => { handleContainer(e.value) }}
                        />
                    </div>

                )
            }

            <div className='app-user-list'>

                <h3>Stock disponible par type de locker</h3>
                <Row>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers XS'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>{
                                    initialStock?.xs ?
                                        availableStock?.xs?.hasOwnProperty(date) ? availableStock?.xs[date]+'/'+initialStock?.xs : initialStock?.xs+'/'+initialStock?.xs
                                    : ' Indisponible' 
                                    }       
                                </h3>
                                    
                            }
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers S'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>{
                                    initialStock?.s ?
                                        availableStock?.s?.hasOwnProperty(date) ? availableStock?.s[date]+'/'+initialStock?.s : initialStock?.s+'/'+initialStock?.s
                                    : ' Indisponible' 
                                    }       
                                </h3>
                                    
                            }
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers L'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>{
                                    initialStock?.l ?
                                        availableStock?.l?.hasOwnProperty(date) ? availableStock?.l[date]+'/'+initialStock?.l : initialStock?.l+'/'+initialStock?.l
                                    : ' Indisponible' 
                                }
                                </h3>
                            } 
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers M'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        initialStock?.m ?
                                            availableStock?.m?.hasOwnProperty(date) ? availableStock?.m[date]+'/'+initialStock?.m : initialStock?.m+'/'+initialStock?.m
                                        : ' Indisponible' 
                                    }
                                </h3>
                            }  
                        />
                    </Col>

                    
                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers XL'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        initialStock?.xl ?
                                            availableStock?.xl?.hasOwnProperty(date) ? availableStock?.xl[date]+'/'+initialStock?.xl : initialStock?.xl+'/'+initialStock?.xl
                                        : ' Indisponible' 
                                    }
                                </h3>
                            }  
                        />
                    </Col>

                    <Col lg='3' sm='6'>
                        <StatsHorizontal
                            color='primary'
                            statTitle='Lockers MB'
                            icon={<DoorBackIcon size={20} />}
                            renderStats={
                                <h3 className='fw-bolder mb-75'>
                                    {
                                        initialStock?.mb ?
                                            availableStock?.mb?.hasOwnProperty(date) ? availableStock?.mb[date]+'/'+initialStock?.mb : initialStock?.mb+'/'+initialStock?.mb
                                        : ' Indisponible' 
                                    }
                                </h3>
                            }
                        />
                    </Col>

                </Row>

            </div>
            <div className='app-user-list'>

                <h3>Stock disponible par service</h3>
                <Row>
                    {serviceStock && serviceStock.map((item, index) => (

                        <Col lg='3' sm='6'>
                            <StatsHorizontal
                                color='primary'
                                statTitle={item.serviceType}
                                icon={<DoorBackIcon size={20} />}
                                renderStats={
                                    <h3 className='fw-bolder mb-75'>
                                        {
                                            item.initialStock ?
                                                item?.availableStock ? item.availableStock+'/'+item.initialStock : item.initialStock+'/'+item.initialStock
                                            : ' Indisponible' 
                                        }
                                    </h3>
                                }
                            />
                        </Col>
                    ))}

                    
                </Row>

            </div>


            <Modal
                isOpen={show}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>

                <ModalBody className='px-5 pb-5'>
                    <div className=" tw-flex tw-flex-row tw-w-full tw-font-bold tw-text-[22px] " >
                        Liste des réservations                    
                    </div>
                    <DialogReservation event={id} />
                </ModalBody>

            </Modal>

        </div>

    </div>

  );

};

export default ViewEvent;