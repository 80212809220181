// ** React Imports
import { Row, Col, InputGroup, Input } from "reactstrap";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import { Card, CardBody, Button } from 'reactstrap'
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { NotificationManager } from 'react-notifications';
import {
    Modal,
    ModalBody,
    ModalHeader
  } from 'reactstrap'
import { CMSApi } from "../../api/cmsApi";
import produce from "immer";


const Partenaire = () => {

  const [patners, setPatners] = useState([]);
  const [loading, setLoading ] = useState(false);
  const [load, setLoad ] = useState(false);

  const [show, setShow] = useState(false)
  const [modalType, setModalType] = useState('Add New')

  const schema = yup.object({
    name: yup.string().required('Entrez un titre').min(3, "Le titre doit contenir au moins 3 caractères"),
    link: yup.string( ).url("Url invalide"),
    // image: yup.mixed().test("file", "Choississez une image", (value) => {
    //     if (value?.length > 0) {  
    //       return true;
    //     }
    //     return false;
    // })
    // .test("fileSize", "Choississez une image inférieur à 100kb", (value) => {
    //     if (value?.["0"].size > 100000) {  
    //       return false;
    //     }
    //     return true;
    // })
  }).required();


  const { reset, control, handleSubmit, formState:{ errors, isDirty } } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema)
  });


  const getAllPatner = async() => {

    setLoading(true)
    const res = await CMSApi.getAllPatner()
    setLoading(false)

    if (res.success) 
    {

        if (res.success) 
        {
            setPatners(res.data)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }
    else
    {
      NotificationManager.error(`${res.message}`, '', 3000)
    }

  }

  useEffect(() => {
    getAllPatner()
  }, []);

  const changeHandle = async (data) => {

    if(isDirty)
    {
      setLoad(true)
      const res = await CMSApi.putLink(data)
      setLoad(false)
  
      if (res.success) 
      {
  
        reset(res.data.data)
        NotificationManager.success('Liens à jours', '', 3000)

      }
      else
      {
        NotificationManager.error(`${res.message}`, '', 3000)
      }

    }else
    {
      NotificationManager.error('Aucune modification éffectuée', '', 3000)
    }

  }

  const handleAddPartener = async(data) => {
        
    setLoad(true)
    const res = await CMSApi.createPatner(data)
    setLoad(false)
    
    if (res.success) 
    {
        setPatners([res.data.data, ...patners])
        setShow(false)
        NotificationManager.success("Partenaire ajouté avec succès ", '', 3000)
    }
    else
    {
        NotificationManager.error(`${res.message}`, '', 3000)
    }

    }

    const handlePutJob = async(data, index) => {

        let datanew = data;
        delete datanew.created_at;
        delete datanew.id;
        delete datanew.index;

        setLoad(true)
        const res = await CMSApi.putJob(datanew, data.id)
        setLoad(false)
        
        if (res.success) 
        {
            setPatners(
                produce((draft) => {
                    draft[index] = res.data.data
                })
            )
            setShow(false)
            NotificationManager.success("Partenaire modifier avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }

    const handleDelete = async() => {

        setLoad(true)
        const res = await CMSApi.deletePatner(modalType.id)
        setLoad(false)
        
        if (res.success) 
        {
            setPatners(
                produce((draft) => {
                  draft.splice(modalType.index, 1)
                })
            )
            setShow(false)
            NotificationManager.success("Partenaire supprimer avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }
    }

  const onSubmit = data => {

    if (modalType === 'Ajouter') {
        handleAddPartener(data)
    }
    else if (modalType === 'Modifier') {

        if (isDirty) {
            handlePutJob(data, data.index)
        }
        else{
            NotificationManager.error(`Aucun champ modifié`, '', 3000)
        }

    }
    
}

  const handleModalClosed = () => {
        setModalType('Add New')
        reset({name: "", link: "", image: ""})
    }

  if(loading)
  {
    return(
        <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center" >
            Loading...
        </div>
    )
  }

  return (

    <div className="datatable">

      <div className=" tw-w-full tw-my-3">

        <Breadcrumbs title="Nos Partenaires" />

        <div className=" tw-w-full tw-h-[40px] tw-flex tw-mb-4 tw-justify-end tw-items-end " >

          <Button disabled={load} 
            onClick={() => {
                setModalType('Ajouter')
                setShow(true)
            }}  
            type="submit" className="me-1 btn-fushia "
            >
            Ajouter
          </Button>

        </div>

        <Row>

          {patners.map((lnk, index) => (

            <Col key={index} lg="3" sm="6">

              <Card>

                <CardBody>

                  <div className="d-flex tw-flex-col justify-content-between align-items-center">

                    <div className="tw-w-full ">

                      <h4>{lnk?.name}</h4>

                      <img className=" tw-w-[120px] tw-mt-2 tw-h-[50px] tw-object-contain" src={lnk?.logo && 'https://mylocker-app-backend.herokuapp.com/api/v1/cms/slide/image/'+lnk?.logo}/>
                      
                    </div>

                    <Col className='text-center tw-mt-2 tw-mb-2' xs={12}>

                        {/* <Button
                            onClick={e => {
                                reset({...lnk, index: index})
                                setModalType('Modifier')
                                setShow(true)
                            }} 
                            disabled={load} color='primary' className='me-1'
                        >
                            {load ? "Load..." : "Modifier"}
                        </Button> */}

                        
                        <Button 
                            onClick={() => {
                                setShow(true) 
                                setModalType({...lnk, index: index}) } 
                            } 
                            disabled={load} color='danger' className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'
                        >
                            {load ? "Load..." : "Supprimer"}
                        </Button>

                    </Col>

                  </div>

                </CardBody>

              </Card>

            </Col>

          ))}

        </Row>

        <Modal
            isOpen={show}
            onClosed={handleModalClosed}
            toggle={() => setShow(!show)}
            className='modal-dialog-centered modal-lg'
        >
            <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>

            { typeof(modalType) !== 'object' ?

                <ModalBody className='px-5 pb-5'>
                    <div className='text-center mb-4'>
                        <h1>{modalType} un partenaire</h1>
                    </div>
                    <Row tag='form' onSubmit={handleSubmit(onSubmit)}>
                        
                        <Col xs={12}>
                        
                            <InputGroup>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <Input className="tw-mt-3" invalid={errors?.name && true} {...field} placeholder='Nom du partenaire...' />
                                    )}
                                />
                            </InputGroup>

                            <InputGroup>
                                <Controller
                                    name="link"
                                    control={control}
                                    render={({ field }) => (
                                        <Input className="tw-mt-3" invalid={errors?.link && true} {...field} placeholder='Lien' />
                                    )}
                                />
                            </InputGroup>


                            <InputGroup>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <Input 
                                        onChange={(e) => {
                                            field.onChange(e.target.files["0"]);
                                        }} 
                                        className="tw-mt-3" type='file' accept='.jpg,.jpeg,.png' 
                                        invalid={errors?.image && true} 
                                    />
                                    )}
                                />
                            </InputGroup>
                        </Col>

                        <Col className='text-center mt-2' xs={12}>
                            <Button type='submit' disabled={load} className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'>
                                {load ? "Load..." : "Ajouter"}
                            </Button>
                        </Col>
                        
                    </Row>

                </ModalBody>

            :

                <ModalBody className='px-5 pb-5'>
                    <div className='text-center mb-4'>
                        <h1>Supprimer ce partenaire</h1>
                    </div>
                    <Row tag='form' onSubmit={handleSubmit(onSubmit)}>
                        
                        <Col className="tw-flex tw-justify-center" xs={12}>

                            Voulez vous supprimer ce partenaire ?

                        </Col>

                        <Col className='text-center mt-2' xs={12}>

                            <Button type='submit' onClick={() => setShow(false)} disabled={load} className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'>
                                {load ? "Load..." : "Non"}
                            </Button>

                            <Button type='submit' onClick={() => handleDelete()} disabled={load} color='danger' className='me-1'>
                                {load ? "Load..." : "Oui"}
                            </Button>

                        </Col>
                        
                    </Row>

                </ModalBody>

            }

        </Modal>

      </div>

    </div>

  );

};

export default Partenaire;
