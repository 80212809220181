import produce from "immer"


const faqappState = {

  //ADMIN
  loadFaq: false,
  keywordSearchFaq: false,
  metaFaq: {
    limit: 25,
    current_items: 0,
    total_items: 0,
    offset: 1,
    current_page: 1,
    total_pages: 0
  },
  dataFaq: {
    data: []
  }

}

function faqappReducer(state = faqappState, action) {

  let nextState

  switch (action.type) {

    case 'SetLoadFaq':

        nextState = produce(state, draftState => {
          draftState.loadFaq = action.value
        })
  
        return nextState || state;

    case 'SetDataFaq':

        nextState = produce(state, draftState => {
          draftState.dataFaq.data = action.value.items
          draftState.metaFaq = action.value.meta
        })
  
        return nextState || state;

    case 'SetKeywordSearchFaq':

      nextState = produce(state, draftState => {
        draftState.keywordSearchFaq = action.value
      })
  
      return nextState || state;

    case 'DelFaq':

      let indexAdminDel = state.dataFaq.data.findIndex(user => user.id == action.value)

      nextState = produce(state, draftState => {
        draftState.dataFaq.data.splice(indexAdminDel, 1)
      })
  
      return nextState || state;

    case 'PutFaq':

      let indexAdminPut = state.dataFaq.data.findIndex(user => user.id == action.value.id)

      nextState = produce(state, draftState => {
        draftState.dataFaq.data[indexAdminPut] = action.value
      })
  
      return nextState || state;

    case 'AddFaq':

      nextState = produce(state, draftState => {
        draftState.dataFaq.data.push(action.value)
      })
  
      return nextState || state;
        
    default:

      return state
  }
}


export default faqappReducer;
