import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@mui/material/CircularProgress';
import useFetch from "../../hooks/useFetch";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axios from "axios";

const SliderList = () => {
  const navigate = useNavigate();
  const { data, loading, error, reFetch } = useFetch('/cms/slide');
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      setList(data.slides);

    }
  }, [data]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/cms/slide/${id}`);
      setList(list.filter((item) => item.id !== id));
      NotificationManager.success(` slider deleted successfully`, 'Success', 3000);
    } catch (err) {
      NotificationManager.error(`slider deleted failled`, 'Error', 3000);
    }
  }

  if (loading) {
    return (
      <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center " >
        <CircularProgress sx={{ color: '#E7075B' }} />
      </div>
    )
  }

  return (
    <div className="list">
      <div className="listContainer">
        <div className="datatable">
          <div className="datatableTitle">
            Sliders
            <Link to={'/slider/new'} className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[14px] tw-rounded-lg tw-h-[40px] tw-px-[15px] ">
              Ajouter
            </Link>
          </div>
          <div className="bottom">
            <div className="right">
              {
                list && list.map((item) => (
                  <div key={item.id}>

                    <img
                      src={item.image && `${process.env.REACT_APP_PUBLIC_URL}/cms/slide/image/${item.image}`}
                      alt={item.altText}
                    />
                    <p><strong>Ordre d'apparition : </strong> {item.slide_order}</p>
                    <h2><strong>Titre : </strong>{item.caption}</h2>
                    <h4><strong>Sous Titre : </strong>{item.subtitle}</h4>
                    <p><strong>Slogan : </strong>{item.slot}</p>
                    <div className="cellAction">
                      <button
                        onClick={() => {
                          navigate('/slider/' + item.id,
                            { state: { data: list.find((e) => e.id === item.id) } })
                        }}
                        type="button"
                        className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "
                      >
                        Modifier
                      </button>
                      <div
                        className="deleteButton"
                        onClick={() => handleDelete(item.id)}
                      >
                        Suprimer
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default SliderList;