import "./datatable.scss";
import "./event.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch, batch } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useAuthUser } from 'react-auth-kit'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NotificationManager } from 'react-notifications';
import { EventApi } from '../../api/eventApi'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Col, Row, Button
} from 'reactstrap'




const Datatable = ({ columns, screen, inputText }) => {

  const dispatch = useDispatch();
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [inputsearch, setInputsearch] = useState(inputText);

  const [show, setShow] = useState(false);
  const [loadmo, setLoad] = useState(false)
  const [madaldata, setModalData] = useState(false);

  const searchKeyword = useSelector((state) =>
    (screen === 'admin') ?
      state.adminappReducer.keywordSearchAdm
      : (screen === 'event') ?
        state.eventappReducer.keywordSearchEvt
        : null
  )

  const load = useSelector((state) =>
    (screen === 'admin') ?
      state.adminappReducer.loadAdm
      : (screen === 'event') ?
        state.eventappReducer.loadEvt
        : true
  )

  const meta = useSelector((state) =>
    (screen === 'admin') ?
      state.adminappReducer.metaAdm
      : (screen === 'event') ?
        state.eventappReducer.metaEvt
        : null
  )

  const gridata = useSelector((state) =>
  ((screen === 'admin') ?
    state.adminappReducer.dataAdm.data
    : (screen === 'event') ?
      state.eventappReducer.dataEvt.data
      : []
  ))

  useEffect(() => {
    if (!searchKeyword || searchKeyword.trim().length === 0) {
      setInputsearch("")
    }
  }, [searchKeyword]);


  useEffect(() => {

    if (screen === "admin") {
      dispatch({
        type: "GETALLADMIN",
        value: {
          token: auth().token,
          current_page: 1,
          limit: meta.limit
        }
      })
    }


    if (screen === "event") {
      dispatch({
        type: "GETALLEVENTS",
        value: {
          token: auth().token,
          current_page: 1,
          limit: meta.limit
        }
      })
    }


  }, []);


  const handleDelete = async (data) => {

    dispatch({
      type: "setDialog", payload: {
        active: true,
        view: 'Delete Admin',
        value: data
      }
    })

  };


  const handleDeleteEvent = async () => {

    setLoad(true)
    // dispatch({type: "DelEvt", value: madaldata})

    const res = await EventApi.deleteEvent(madaldata, auth().token)

    if (res.success) {
      NotificationManager.success("Evénement supprimé avec succès", '', 3000)
      dispatch({ type: "DelEvt", value: madaldata })
    }
    else {
      if(res.message === "Event cannot be deleted, already has reservations") {
        NotificationManager.error("Cet événement est déjà réservé. Impossible de le supprimer", '', 3000)
      }else {
        NotificationManager.error("Erreur lors de la suppression de l'évènement. Vérifier votre connexion puis Réessayer", '', 3000)
      }

    }
    setLoad(false)
    setShow(false)
  }


  const actionColumnAdmin = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">

            <div
              className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "
              onClick={() =>
                dispatch({
                  type: "setDrawer", payload: {
                    active: true,
                    view: "Modifier un Utilisateur",
                    value: params.row
                  }
                })}
            >
              Voir / Modifier
            </div>


            {auth().role === "GM" ?

              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row)}
              >
                Supprimer
              </div>

              :

              null}


          </div>
        );
      },
    },
  ];

  const actionEventColumns = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellAction">

            <button onClick={() => navigate(`../events/view/${params.row.id}`, { state: params.row })} className=" tw-w-[30px] tw-h-[30px] tw-shadow-md tw-flex tw-justify-center tw-items-center tw-rounded-full " >
              <VisibilityIcon sx={{ color: '#ffffff' }} fontSize='small' />
            </button>

            {!["OG"].includes(auth().role) ?
              <>
                <Link to={`/events/${params.row.id}`} className=" tw-w-[30px] tw-h-[30px] tw-shadow-md tw-flex tw-justify-center tw-items-center tw-rounded-full " >
                  <EditIcon sx={{ color: '#E7075B' }} fontSize='small' />
                </Link>

                <button onClick={() => { setModalData(params.row.id); setShow(true) }} className=" tw-w-[30px] tw-h-[30px] tw-shadow-md tw-flex tw-justify-center tw-items-center tw-rounded-full " >
                  <DeleteIcon sx={{ color: '#ffffff' }} fontSize='small' />
                </button>
              </>
              :
              null}
          </div>
        );
      },
    },
  ];



  const rowsPerPageCh = (val) => {

    if (screen === "admin") {

      if (inputsearch.length > 0) {
        batch(() => {
          dispatch({ type: "SetRowPerPage", value: val })
          dispatch({
            type: "GETSEARCHALBUMS", value: {
              current_page: meta.current_page,
              limit: val,
              keyword: inputsearch
            }
          })
        })
      }
      else {
        batch(() => {
          dispatch({ type: "SetRowPerPage", value: val })
          dispatch({
            type: "GETALLALBUMS", value: {
              current_page: meta.current_page,
              limit: val
            }
          })
        })
      }

    }
    else if (screen === "users") {

      if (inputsearch.length > 0) {
        batch(() => {
          dispatch({ type: "SetRowPerPage", value: val })
          dispatch({
            type: "GETSEARCHUSERS", value: {
              current_page: meta.current_page,
              limit: val,
              keyword: inputsearch
            }
          })
        })
      }
      else {
        batch(() => {
          dispatch({ type: "SetRowPerPage", value: val })
          dispatch({
            type: "GETALLUSERS", value: {
              current_page: meta.current_page,
              limit: val
            }
          })
        })
      }

    }

  }

  const pageChange = (val) => {

    if (screen === "admin") {

      if (inputsearch.length > 0) {
        dispatch({
          type: "GETSEARCHALBUMS", value: {
            current_page: val + 1,
            limit: meta.limit,
            keyword: inputsearch
          }
        })
      }
      else {
        dispatch({
          type: "GETALLALBUMS", value: {
            current_page: val + 1,
            limit: meta.limit
          }
        })
      }

    }
    else if (screen === "users") {

      if (inputsearch.length > 0) {
        dispatch({
          type: "GETSEARCHUSERS", value: {
            current_page: val + 1,
            limit: meta.limit,
            keyword: inputsearch
          }
        })
      }
      else {
        dispatch({
          type: "GETALLUSERS", value: {
            current_page: val + 1,
            limit: meta.limit
          }
        })
      }

    }

  }

  const onSearchTextChange = (e) => {

    setInputsearch(e.currentTarget.value)

    if (screen === "admin") {

      if (e.currentTarget.value.length > 0) {

        if (e.currentTarget.value.trim().length > 0)
          dispatch({
            type: "GETSEARCHADMIN", value: {
              token: auth().token,
              current_page: 1,
              limit: meta.limit,
              keyword: e.currentTarget.value
            }
          })

      }
      else {
        dispatch({
          type: "GETALLADMIN", value: {
            token: auth().token,
            current_page: 1,
            limit: meta.limit
          }
        })
      }

    }
    else if (screen === "users") {

      if (e.currentTarget.value.length > 0) {

        if (e.currentTarget.value.trim().length > 0)
          dispatch({
            type: "GETSEARCHUSERS", value: {
              current_page: 1,
              limit: meta.limit,
              keyword: e.currentTarget.value
            }
          })

      }
      else {
        dispatch({
          type: "GETALLUSERS", value: {
            current_page: 1,
            limit: meta.limit
          }
        })
      }

    }

  }


  return (

    <div className="datatable">

      <div className=" tw-w-full tw-my-3" >

        {screen === 'admin' ?

          <span>
            Liste des utilisateurs
          </span>

          : screen === 'event' ?

            <span>
              Liste des Evénements
            </span>

            : null

        }

      </div>

      <div className="datatableTitle">

        <div className="tw-relative tw-text-gray-400 tw-min-w-[80%] tw-w-[80%] tw-py-1 sm:tw-min-w-0 sm:tw-w-[60%] md:tw-w-[30%] xl:tw-w-[25%]">
          <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
            <button type="submit" className="tw-shadow-outline">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" className="tw-w-6 tw-h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
            </button>
          </span>
          <input type="tel" hidden />
          <input type="search" value={inputsearch} onChange={(val) => onSearchTextChange(val)} className="tw-py-2 tw-text-sm tw-rounded-md tw-pl-10 tw-w-full focus:tw-outline-none tw-bg-white tw-text-gray-900" placeholder="Search..." autoComplete="false" />
        </div>

        {screen === "event" &&
          ["GM", "CA", "EM", "RMC"].includes(auth().role) ?
          <Link to={`/events/new`}
            className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[14px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "

          >
            Créer un évènement
          </Link>
          : null
        }

        {screen === "admin" &&
          auth().role === "GM" ?
          <button
            onClick={() =>
              dispatch({
                type: "setDrawer", payload: {
                  active: true,
                  view: "Créer un Utilisateur",
                  value: null
                }
              })}
            className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[14px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "
          >
            Créer un utilisateur
          </button>
          : null
        }

      </div>

      <DataGrid
        className="datagrid"
        columns={
          screen === "admin" ?
            columns.concat(actionColumnAdmin)
            :
            screen === "event" ?
              columns.concat(actionEventColumns)
              :
              columns
        }
        getRowId={() => uuidv4()}
        rows={
          screen === 'event' ?
            auth().role === 'EM' ?
              gridata.filter((item, index) => item.admin_id === auth().id || item.event_manager_id === auth().id)
              :
              auth().role === 'OG' ?
                gridata.filter((item, index) => item.organiser_email === auth().email)
                :
                gridata
            :
            gridata
        }
        page={meta.current_page - 1}
        disableColumnSelector={true}
        rowCount={meta.total_items} //total item in DB
        disableSelectionOnClick
        getRowHeight={() => 'auto'}
        paginationMode="server"
        pageSize={meta.limit}
        onPageChange={(newPage) => pageChange(newPage)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => rowsPerPageCh(newPageSize)}
        sx={[
          {
            border: 0,
            backgroundColor: 'white',
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
              outline: "none"
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#EEF0F8',
              borderWidth: 0
            },
            '& .MuiDataGrid-cell': {
              // borderRight: 0.5,
              // borderColor: '#C1C1C1',
              // borderBottom: 0.5,
              px: 1, py: 1,
              fontSize: 13,
              fontWeight: '500',
              // justifyContent: 'center',
              // alignItems: 'center',
              wordBreak: 'break-word'
            },
            '& .MuiDataGrid-footerContainer': {
              paddingRight: 8,
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none"
            },
          },

        ]}

        loading={load}
      />
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        onClosed={() => setModalData(false)}
        className='modal-dialog-centered modal-lg'
      >
        <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>

        <ModalBody className='px-5 pb-5'>

          <div className='text-center mb-4'>
            <h1>Supprimer
              {screen === 'event' ?
                " cet évènement" : null}
            </h1>
          </div>
          <Row>

            <Col className="tw-flex tw-justify-center" xs={12}>

              {screen === 'event' ?
                "Voulez vous supprimer cet évènement ?"
                :
                null
              }

            </Col>

            <Col className='text-center mt-2' xs={12}>

              <Button type='submit' onClick={() => setShow(false)} disabled={loadmo} className='me-1'>
                {loadmo ? "Load..." : "Non"}
              </Button>

              <Button
                type='submit'
                disabled={loadmo}
                color='danger'
                className='me-1'
                onClick={() =>
                  screen === 'event' ?
                    handleDeleteEvent()
                    : null
                }
              >
                {loadmo ? "Load..." : "Oui"}
              </Button>

            </Col>
          </Row>

        </ModalBody>

      </Modal>

    </div>

  );
};

export default Datatable;
