// ** Reactstrap Imports
import { Fragment, useState, useEffect } from 'react'
import { Card, CardBody, CardText, Form, Input, InputGroup, InputGroupText,  AccordionBody,
    AccordionItem,
    AccordionHeader,
    UncontrolledAccordion,
    Row,
    Col,
    Button
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  UncontrolledTooltip
} from 'reactstrap'

import { CMSApi } from '../../api/cmsApi'
import { useDispatch, useSelector } from 'react-redux'
import produce from "immer";
import Breadcrumbs from '../../@core/components/breadcrumbs'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { NotificationManager } from 'react-notifications';

import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'

import illustration from '../../assets/images/illustration/faq-illustrations.svg'
import './cms.scss';


const Job = () => {

    const schema = yup.object({
        title: yup.string().required('Entrez un titre du job').min(3, "Le titre doit contenir au moins 3 caractères"),
        // description: yup.string().required('Entrez une desciption du job').min(3, "la description doit contenir au moins 3 caractères"),
        indeed_link: yup.string().url("Url indeed invalide"),
        linkedin_link: yup.string().url("Url indeed invalide"),
        place: yup.string().required("Entrez l'emplacement du jobs").min(3, "L'emplacement doit contenir au moins 3 caractères"),
        time: yup.string().required("Champs requis")
    }).required();

    const { reset, control, handleSubmit, formState:{ errors, isDirty } } = useForm({
        resolver: yupResolver(schema)
    });


    const [loading, setLoading ] = useState(false);
    const [load, setLoad ] = useState(false);

    const [jobs, setjobs] = useState([]);
    const [show, setShow] = useState(false)
    const [showlist, setshowList] = useState(false)
    const [modalType, setModalType] = useState('Add New')
    const [modalTypelist, setModalTypelist] = useState(null)
    const [candidats, setCandidats] = useState('load')

    const [indexJob, setIndexJob] = useState(null)
    
    const initialContent = indexJob !== null ? jobs[indexJob]?.description : `<p></p>`;

    const contentBlock = htmlToDraft(initialContent)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const [content, setContent] = useState(() => EditorState.createWithContent(contentState));

    const getAllJob = async(val) => {

        setLoading(true)
        const res = await CMSApi.getAllJob()
        setLoading(false)
        
        if (res.success) 
        {
            setjobs(res.data)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }
    }

    const getAllCandidat = async() => {

        setCandidats('load')
        const res = await CMSApi.getAllCandidat(modalTypelist.id)

        if (res.success) 
        {
            setCandidats(res.data)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }

    useEffect(() => {
        getAllJob()
    }, [])


    const handleAddJob = async(data) => {
        
        setLoad(true)
        const res = await CMSApi.createJob(data)
        setLoad(false)
        
        if (res.success) 
        {
            setjobs([res.data.data, ...jobs])
            setShow(false)
            NotificationManager.success("Job ajouté avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }

    const handlePutJob = async(data, index) => {        
        setLoad(true)
        const res = await CMSApi.putJob(data, data.id)
        setLoad(false)
        
        if (res.success) 
        {
            setjobs(
                produce((draft) => {
                  draft[index] = res.data.data
                })
            )
            setShow(false)
            NotificationManager.success("Job modifier avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }

    const handleDelete = async() => {

        setLoad(true)
        const res = await CMSApi.deleteJob(modalType.id)
        setLoad(false)
        
        if (res.success) 
        {
            setjobs(
                produce((draft) => {
                  draft.splice(modalType.index, 1)
                })
            )
            setShow(false)
            NotificationManager.success("Job supprimer avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }
    }

    const onSubmit = data => {
        const html = draftToHtml(convertToRaw(content.getCurrentContent()));
        const job = {
            ...data,
            description: html
        }

        if (modalType === 'Ajouter') {
            handleAddJob(job)
        }
        else if (modalType === 'Modifier') {

            handlePutJob(job, data.index)
            // if (isDirty) {
            //     handlePutJob(job, data.index)
            // }
            // else{
            //     NotificationManager.error(`Aucun champ modifié`, '', 3000)
            // }

        }
        
    }

    const handleModalClosed = () => {
        setModalType('Add New')
        reset({title: "", description: "", indeed_link: "", linkedin_link: "", place: "", time: ""})
    }

    if(loading)
    {
        return(

            <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center" >
                Loading...
            </div>

        )
    }

  return (

    <div className="datatable">

        <div className=" tw-w-full tw-my-3" >

            <Breadcrumbs title='JOBS' />

            <Row>

                <Col xl={4} md={6}>
                    <Card>
                        <Row>
                        <Col sm={5}>
                            <div className='d-flex align-items-end justify-content-center h-100'>
                            <img className='img-fluid mt-2' src={illustration} alt='Image' width={85} />
                            </div>
                        </Col>
                        <Col sm={7}>
                            <CardBody className='text-sm-end text-center ps-sm-0'>
                            <Button
                                
                                className='text-nowrap mb-1 btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] '

                                onClick={() => {
                                    setModalType('Ajouter')
                                    setShow(true)
                                    setIndexJob(null)
                                }}
                            >
                                Ajouter
                            </Button>
                            </CardBody>
                        </Col>
                        </Row>
                    </Card>
                </Col>

                {jobs.map((item, index) => {
                    return (
                        <Col key={index} xl={4} md={6}>
                            <Card>

                                <CardBody>
                                    {/* <div className='d-flex justify-content-between'>
                                        <span className="tw-line-clamp-3" >{item.description}</span>
                                        <AvatarGroup data={item.users} />
                                    </div> */}
                                    <div className='d-flex justify-content-between align-items-end mt-1 pt-25'>
                                        <div className='role-heading tw-w-full'>
                                            <h4 className='fw-bolder'>{item.title}</h4>

                                            <div className="tw-mb-4 tw-full tw-flex tw-justify-between" >

                                                <span>{item.place}</span>
                                                <span>{item.time}</span>

                                            </div>

                                            <div className="tw-mb-4 tw-full tw-flex tw-justify-between" >
                                                <Link
                                                    to='/'
                                                    className='role-edit-modal  '
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        reset({...item, index: index})
                                                        setModalType('Modifier')
                                                        setIndexJob(index)
                                                        setShow(true)
                                                    }}
                                                >
                                                    <small className='fw-bolder'>Modifier le job</small>
                                                </Link>

                                                <Button type='submit' 
                                                    onClick={() => {
                                                        setShow(true) 
                                                        setModalType({...item, index: index}) } 
                                                    } 
                                                    color='danger' className='me-1'
                                                >
                                                    {load ? "Load..." : "Supprimer"}
                                                </Button>

                                            </div>

                                            <Button type='submit' 
                                                onClick={() => {
                                                    setshowList(true)
                                                    setModalTypelist(item) 
                                                }} 
                                                className='me-1 tw-w-full btn-fushia '
                                            >
                                                Liste des Postulants
                                            </Button>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}


            </Row>

            <Modal
                isOpen={show}
                onClosed={handleModalClosed}
                toggle={() => setShow(!show)}
                className='modal-dialog-centered modal-lg'
            >
                <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>

                { typeof(modalType) !== 'object' ?

                    <ModalBody className='px-5 pb-5'>
                        <div className='text-center mb-4'>
                            <h1>{modalType} un job</h1>
                        </div>
                        <Row tag='form' onSubmit={handleSubmit(onSubmit)}>
                            
                            <Col xs={12}>
                            
                                <InputGroup>
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.title && true} {...field} placeholder='Titre du job...' />
                                        )}
                                    />
                                </InputGroup>

                                {/* <InputGroup>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => (
                                            <Input type="textarea" invalid={errors?.description && true} {...field} className="tw-mt-3" placeholder='Description su jobs...' />
                                        )}
                                    />
                                </InputGroup> */}


                                <InputGroup>
                                    <Controller
                                        name="indeed_link"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.indeed_link && true} {...field} placeholder='Lien Indeed...' />
                                        )}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Controller
                                        name="linkedin_link"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.linkedin_link && true} {...field} placeholder='Lien linkedin...' />
                                        )}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Controller
                                        name="place"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.place && true} {...field} placeholder='Place' />
                                        )}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Controller
                                        name="time"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.time && true} {...field} placeholder='Ex: Temps Plein' />
                                        )}
                                    />
                                </InputGroup>

                                <Col sm='12' className='mt-2 mb-2'>
                                    <Editor 
                                        editorState={content} 
                                        onEditorStateChange={setContent} 
                                        placeholder='Ajouter une description'
                                    />
                                </Col>


                            </Col>

                            <Col className='text-center mt-2' xs={12}>
                                <Button type='submit' disabled={load} className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'>
                                    {load ? "Load..." : modalType}
                                </Button>
                            </Col>
                            
                        </Row>

                    </ModalBody>

                :

                    <ModalBody className='px-5 pb-5'>
                        <div className='text-center mb-4'>
                            <h1>Supprimer ce job</h1>
                        </div>
                        <Row tag='form' onSubmit={handleSubmit(onSubmit)}>
                            
                            <Col className="tw-flex tw-justify-center" xs={12}>

                                Voulez vous supprimer ce Job ?

                            </Col>

                            <Col className='text-center mt-2' xs={12}>

                                <Button type='submit' onClick={() => setShow(false)} disabled={load} className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'>
                                    {load ? "Load..." : "Non"}
                                </Button>

                                <Button type='submit' onClick={() => handleDelete()} disabled={load} color='danger' className='me-1'>
                                    {load ? "Load..." : "Oui"}
                                </Button>

                            </Col>
                            
                        </Row>

                    </ModalBody>

                }

            </Modal>


            {/* list postulant */}
            <Modal
                isOpen={showlist}
                onOpened={() =>  getAllCandidat() }
                onClosed={() => { setCandidats('load'); setModalTypelist(null) }}
                className='modal-dialog-centered modal-lg'
                scrollable={true}
            >
                <ModalHeader className='bg-transparent' toggle={() => setshowList(!showlist)}></ModalHeader>

                <ModalBody className='px-5 pb-5'>

                    <div className=" tw-flex tw-flex-row tw-w-full tw-font-bold tw-text-[22px] " >
                        {modalTypelist?.title}                    
                    </div>

                    {candidats === 'load' ?

                        <div className=" tw-w-full tw-h-[200px] tw-flex tw-justify-center tw-items-center " >
                            Chargement...
                        </div>

                        :

                        candidats.length > 0 ?
                        
                            <div className=" tw-w-full tw-flex tw-flex-col tw-mt-3 " >

                                {candidats.map((item,id) => (

                                    <div className="tw-w-full tw-flex-row tw-flex tw-h-[90px] tw-my-2 tw-justify-center tw-bg-slate-100 tw-rounded-md tw-items-center" >

                                        <div className=" tw-flex tw-h-full tw-justify-center tw-flex-col tw-flex-1 tw-px-4 tw-py-2 " >
                                            
                                            <span className=" tw-font-bold tw-text-[15px]" >{item.fullname}</span>

                                            <div className=" tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 tw-gap-y-2 " >
                                                <span className=" tw-font-normal tw-text-[15px] " >{item.email}</span>
                                                <span className=" tw-font-normal tw-text-[15px] " >{item.phone}</span>
                                            </div>

                                        </div>

                                        <a target='_blank' href={'https://mylocker-app-backend.herokuapp.com/api/v1/cms/slide/image/'+item?.cv} className=" tw-py-2 tw-px-5 tw-mx-3 tw-text-[12.5px] tw-rounded-md tw-bg-green-500 tw-text-white " >
                                            Voir CV
                                        </a>

                                    </div>

                                ))}

                            </div>

                        :

                        <div className=" tw-w-full tw-h-[200px] tw-flex tw-justify-center tw-items-center " >
                            Pas de postulant
                        </div>
                    }

                </ModalBody>

            </Modal>
            
        </div>



    </div>

  )
}

export default Job
