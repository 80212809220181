import React from "react";
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import InputPasswordToggle from "../../../@core/components/input-password-toggle";
import { useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

// ** Reactstrap Imports
import { Button, Label, FormText, Form, Input } from 'reactstrap'

const CreateAdminForm = ({modify, datas}) => {

    const schema = yup.object().shape({

        last_name: yup.string().required("le nom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un nom valide" ),
        first_name: yup.string().required("le prenom est requis").matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Entrez un prenom valide" ),
        email: yup.string().required("le mail est requis").email('Entrez un email valide'),
        telephone: yup.string().required("le numéro est requis").min(10, 'Entrez un numéro valide'),
        role:  yup.string().required("le role est requis"),
        password: yup.string().required("le mot de passe est requis").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$@$!%*#?&()+-_]{8,}$/, "Minimum huit caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre" ),

    },[]).required();

    const roleOptions = [
      { label: 'GM', value: 'GM' },
      { label: 'GMA', value: 'CA' },
      { label: 'EV', value: 'EM' },
      { label: 'RMC', value: 'RMC' }
    ]

      // ** Vars
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({ 
      resolver: yupResolver(schema)
    })

    const dispatch = useDispatch();

  // ** Function to handle form submit
  const onSubmit = async data =>  {
    dispatch({type: "setDialog", payload: {
        active: true,
        view: 'Add Admin',
        value: data
      } 
    })

  }

  return (

    <div>

      <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

        <div className="mb-1">

          <Label className="form-label" for="fullName">
            Nom <span className="text-danger">*</span>
          </Label>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                id="last"
                placeholder="Doe"
                invalid={errors?.last_name && true}
                {...field}
              />
            )}
          />

        </div>

        <div className="mb-1">
          <Label className="form-label" for="fullName">
            Prenoms <span className="text-danger">*</span>
          </Label>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                id="first_name"
                placeholder="John"
                invalid={errors?.first_name && true}
                {...field}
              />
            )}
          />
        </div>

        <div className="mb-1">
          <Label className="form-label" for="userEmail">
            Email <span className="text-danger">*</span>
          </Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                type="email"
                autoComplete=""
                id="userEmail"
                placeholder="john.doe@example.com"
                invalid={errors?.email && true}
                {...field}
              />
            )}
          />
        </div>

        <div className="mb-1">
          <Label className="form-label" for="contact">
            Contact <span className="text-danger">*</span>
          </Label>
          <Controller
            name="telephone"
            control={control}
            render={({ field: { ref, ...field } }) => (
                <PhoneInput
                    {...field}
                    country={'fr'}
                />
            )}
          />
          <small className="error tw-mt-1 text-danger">
            {errors.telephone?.message}
            </small>
        </div>

        <div className="mb-1 tw-z-[9999] ">
          <Label className="form-label" for="role">
            Choisir un rôle
          </Label>
          <Controller
            name="role"
            control={control}
            render={({ field: { onChange, value, ref, name } }) => (
              <Select
                isClearable={false}
                Searchable={false}
                inputRef={ref}
                classNamePrefix="select"
                options={roleOptions}
                value={roleOptions.find((c) => c.value === value)}
                onChange={val => onChange(val.value)}
              />
            )}
          />
            <small className="error tw-mt-1 text-danger">
                {errors.role?.message}
            </small>
        </div>

        <div className="mb-1">
          <Label className="form-label" for="userEmail">
            Mot de Passe du compte <span className="text-danger">*</span>
          </Label>          
          
            <Controller
              control={control}
              name="password"
              render={({ field: { ref, ...fieldProps} }) => (
                <InputPasswordToggle autocomplete="new-password" innerRef={ref} fieldProps={fieldProps} invalid={errors?.password} className='input-group-merge' id='login-password' />
              )}  
            />
            <small className="error tw-mt-1 text-danger">
              {errors.password?.message}
            </small>
        </div>


        <Button type="submit" className="me-1">
          Créer un utilisateur
        </Button>

      </Form>

    </div>

  );

};

export default CreateAdminForm;
