// ** React Imports
import {useState, useEffect} from 'react'
import { useLocation, useNavigate} from "react-router-dom";
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./cms.scss"

import Breadcrumbs from '../../@core/components/breadcrumbs'

// ** Reactstrap Imports
import { Row, Col, Card, CardBody,  Form, Label, Input, Button } from 'reactstrap'


const Edit = () => {

    let {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(state);
        if(!state){
            navigate("/notFound", { replace: true })
        }
    }, []);
  
  const initialContent = state.lng === 'fr' ? state.content : state.content_english

  const contentBlock = htmlToDraft(initialContent)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)


   const [content, setContent] = useState(() => EditorState.createWithContent(contentState));

   const handleModiTerms = () => {

        const html = draftToHtml(convertToRaw(content.getCurrentContent()));

        dispatch({type: "setDialog", 
            payload: {
                active: true,
                view: 'Modifier Terms',
                value: {
                    title: state.title,
                    subtitle: state.subtitle,
                    content: state.lng === 'fr' ? `${html}` : state.content,
                    content_english: state.lng === 'en' ? `${html}` : state.content_english,
                }
            } 
        })

    
   } 


  return (

    <div className="datatable mentionDiv">

        <div className=" tw-w-full tw-my-3" >

            <div className='blog-edit-wrapper'>

                <Breadcrumbs title={` Modifier les ${state.subtitle === "ML" ? 'Mentions legales' : state.subtitle}`} data={[]} />

                <Row>
                <Col sm='12'>

                    <Card>

                    <CardBody>

                        <Form className='mt-2' onSubmit={e => e.preventDefault()}>
                        <Row>

                            <Col md='6' className='mb-2'>
                            <Label className='form-label' for='blog-edit-title'>
                                Titre
                            </Label>
                            <Input id='blog-edit-title' disabled={true} value={state.title} />
                            </Col>

                            <Col md='6' className='mb-2'>
                            <Label className='form-label' for='blog-edit-slug'>
                                Sous-titre
                            </Label>
                                <Input id='blog-edit-slug' disabled={true} value={state.subtitle} />
                            </Col>

                            <Col sm='12' className='mb-2'>
                            <Label className='form-label'>Content</Label>
                            <Editor 
                                editorState={content} 
                                onEditorStateChange={setContent} 
                            />
                            </Col>


                            <Col className='mt-50'>
                                <Button onClick={() => handleModiTerms()} className='me-1 tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white btn-fushia'>
                                    Sauvegarder les modifications
                                </Button>
                                <Button onClick={() => navigate(-1) } color='secondary' outline>
                                    Annuler les modifications
                                </Button>
                            </Col>

                        </Row>

                        </Form>

                    </CardBody>

                    </Card>

                </Col>

                </Row>

            </div>

        </div>

    </div>

  )

}

export default Edit
