import React from "react";
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";

import { Provider } from 'react-redux'
import { persistStore} from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from './redux/ConfigStore';
import { AuthProvider } from 'react-auth-kit'

const root = ReactDOM.createRoot(document.getElementById('root'));

const Store = configureStore();
const persistor = persistStore(Store)


root.render(

  <DarkModeContextProvider>

    <AuthProvider
      authType = {'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
    
      <Provider store={Store}>
        <PersistGate persistor={persistor}>
          <App />
          <NotificationContainer />
        </PersistGate>
      </Provider>
    
    </AuthProvider>

  </DarkModeContextProvider>

);
