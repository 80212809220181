import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';

import DeleteAdminDialog from './dialogBody/deleteadmin';
import AddAdminDialog from './dialogBody/addamin';
import ModifTerms from './dialogBody/modifterms';
import DeleteFaq from './dialogBody/deleteFaq';
import ModifFaq from './dialogBody/modiffaq';

const DialogPop = () => {

    const dialog = useSelector((state) => state.paramsappReducer.dialog)

    return (
        
        <Dialog open={dialog.active}>

          {dialog.view === "Delete Admin" && <DeleteAdminDialog  data={dialog.value} />}
          {dialog.view === "Add Admin" && <AddAdminDialog data={dialog.value} />}
          {dialog.view === "Modifier Terms" && <ModifTerms data={dialog.value} />}
          {dialog.view === "Modif Faq" && <ModifFaq data={dialog.value} />}
          {dialog.view === "Delete Faq" && <DeleteFaq data={dialog.value} />}

        </Dialog>

    );
}

export default DialogPop;
