import {useState, useEffect} from "react"
import {useDispatch, useSelector, batch} from 'react-redux'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationManager } from 'react-notifications';
import { useAuthUser } from 'react-auth-kit'

import { CMSApi } from "../../../api/cmsApi";


const DeleteFaq = ({data}) => {

    const auth = useAuthUser();
    const dispatch = useDispatch()

    const [load, setload] = useState(false);

    const handleClose = () => {
        dispatch({type: 'setDialog', payload: { active: false, view: null } })
    };

    
    const onSubmit = async() => {

        setload(true)

        const res = await CMSApi.deleteFAQ(data) 

        setload(false)

        if (res.success) 
        {

            batch(() => {
                dispatch({type: 'setDialog', payload: { active: false, view: null } })
                dispatch({type: 'DelFaq', value: data })
            })

            NotificationManager.success("Administrateur supprimé avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error("Erreur lors de la supression du FAQ. Vérifier votre connexion puis Réessayer", '', 3000)
        }

    };
    

    return (

        <>
            <DialogTitle sx={{backgroundColor: '#E7075B', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <span className="tw-font-bold tw-text-[15px] tw-text-white" >SUPPRIMER FAQ</span>
            </DialogTitle>   

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="tw-text-md tw-mt-3" >
                        Êtes-vous sûr(e) de vouloir supprimer la Faq ? <br/>
                    </div>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white" onClick={() => handleClose()}>NON</button>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]" onClick={() => onSubmit()}>
                    {load ?
                        <CircularProgress size={16} sx={{color: 'white'}} />
                        :
                        "OUI"
                    }
                </button>
            </DialogActions> 

        </>

    );

}

export default DeleteFaq;
