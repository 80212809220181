// ** Reactstrap Imports
import { Fragment, useState, useEffect } from 'react'
import { Card, CardBody, CardText, Form, Input, InputGroup, InputGroupText,  AccordionBody,
    AccordionItem,
    AccordionHeader,
    UncontrolledAccordion,
    Row,
    Col,
    Button
} from 'reactstrap'
import { CMSApi } from '../../api/cmsApi'
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumbs from '../../@core/components/breadcrumbs'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { NotificationManager } from 'react-notifications';

// import Flag from '../../utils/flag/Flag'

const schema = yup.object({
    question: yup.string().required('Entrez une question'),
    english_question: yup.string().required('Entrez une question'),
    answer: yup.string().required('Entrez une réponse'),
    english_answer: yup.string().required('Entrez une réponse')
}).required();

const Faq = () => {

    const { reset, control, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const dispatch = useDispatch();
    const meta = useSelector((state) => state.faqappReducer.metaFaq )
    const load = useSelector((state) => state.faqappReducer.loadFaq )
    const faqs = useSelector((state) => state.faqappReducer.dataFaq.data )

    const [loading, setLoading ] = useState(false);

    const getAllFaq = (val) => {
        dispatch({type: "GETALLFAQ", 
            value: val
        })
    }

    useEffect(() => {
        getAllFaq({
            current_page: 1,
            limit: meta.limit
        })
    }, [])

    const handleAddFaq = async(data) => {
        
        setLoading(true)
        const res = await CMSApi.createFAQ(data)
        setLoading(false)
        
        if (res.success) 
        {
            dispatch({type: 'AddFaq', value: res.data.data })
            reset({
                question: "",
                answer: "",
                english_question: "",
                english_answer: ""
              });
            NotificationManager.success("Faq ajouté avec succès ", '', 3000)
        }
        else
        {
            NotificationManager.error(`${res.message}`, '', 3000)
        }

    }

    if(load)
    {
        return(

            <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center" >
                Loading...
            </div>

        )
    }

  return (

    <div className="datatable">

        <div className=" tw-w-full tw-my-3" >

            <Breadcrumbs title='FAQ' />

            <div id='faq-search-filter'>

                <Card
                    className='faq-search'
                    style={{
                    backgroundImage: `url('@src/assets/images/banner/banner.png')`
                    }}
                >

                    <CardBody className='text-center'>
                        <h2 className=''>Répondons à quelques questions</h2>
                        <Form className='faq-search-input' onSubmit={handleSubmit(handleAddFaq)} >

                            <div className='tw-flex tw-gap-3'>

                                <InputGroup>
                                    <Controller
                                        name="question"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.question && true} {...field} placeholder='Question en français' />
                                        )}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Controller
                                        name="answer"
                                        control={control}
                                        render={({ field }) => (
                                            <Input type="textarea" invalid={errors?.answer && true} {...field} className="tw-mt-3" placeholder='Réponse en français' />
                                        )}
                                    />
                                </InputGroup>

                            </div>

                            <div className='tw-flex tw-gap-3'>

                                <InputGroup>
                                    <Controller
                                        name="english_question"
                                        control={control}
                                        render={({ field }) => (
                                            <Input className="tw-mt-3" invalid={errors?.english_question && true} {...field} placeholder='Question en anglais...' />
                                        )}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <Controller
                                        name="english_answer"
                                        control={control}
                                        render={({ field }) => (
                                            <Input type="textarea" invalid={errors?.english_answer && true} {...field} className="tw-mt-3" placeholder='Réponse en anglais...' />
                                        )}
                                    />
                                </InputGroup>
                                
                            </div>


                            <Button disabled={loading} type="submit" className="btn-fushia me-1 tw-mt-4 ">
                                {loading ? "Loading..." : "Ajouter la Faq"}
                            </Button>

                        </Form>
                    </CardBody>

                </Card>

            </div>

            <div id='faq-tabs tw-pb-[20px]'>
                <Row className="tw-flex tw-flex-col tw-justify-center tw-items-center" >
                    <Col lg='10' md='10' sm='12' className=" tw-mb-[30px]">
                        <UncontrolledAccordion className='accordion-margin mt-2' defaultOpen='0'>
                            {faqs.map((faq, index) => (
                                <AccordionItem key={index}>
                                    <AccordionHeader tag='div' targetId={String(index + 1)}>
                                        FAQ { index + 1}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={String(index + 1)}>
                                                <h3 className='tw-font-size-[12px]'>
                                                    {faq.question}
                                                </h3>
                                                <br />
                                                {faq.answer}
                                                <hr />
                                                <h3>
                                                    {faq.english_question}
                                                </h3>
                                                <br />
                                                {faq.english_answer} 

                                        <div className=" tw-w-full tw-h-[40px] tw-flex tw-mt-4 tw-justify-end tw-items-end " >

                                            <Button 
                                                onClick={() =>  dispatch({type: "setDialog", payload: {active: true, view: 'Modif Faq', value: faq } })} 
                                                disabled={loading} type="submit" className="me-1"
                                            >
                                                {loading ? "Loading..." : "Modifier"}
                                            </Button>

                                            <Button 
                                                onClick={() =>  dispatch({type: "setDialog", payload: {active: true, view: 'Delete Faq', value: faq.id } })} 
                                                disabled={loading} type="submit" className="me-1 " color="danger" 
                                            >
                                                {loading ? "Loading..." : "Supprimer"}
                                            </Button>

                                        </div>

                                    </AccordionBody>
                                </AccordionItem>
                            ))}
                        </UncontrolledAccordion>
                    </Col>
                </Row>
            </div>
            
        </div>

    </div>

  )
}

export default Faq
