import React from 'react'
import Datatablereserv from '../datatable/Datatablereservation'

function DialogReservation({ event }) {
  return (

    <div className="list">
      <div className="listContainer">
        <Datatablereserv id={event} columns={
          [
            { field: "formatted_date", headerName: "Date", flex: 3, minWidth: 180 },
            { field: "uid", headerName: "N. de reserv", width: 100 },
            { field: "type_of_reservation", headerName: "Type de réservations", width: 200},
            { field: "total", headerName: "Montant payé", flex: 3, minWidth: 180, },
            { field: "reserv_date", headerName: "Date(s) réservée(s) ", flex: 3, minWidth: 500, },
            { field: "battery", headerName: "Battery", flex: 3, minWidth: 200, },
            { field: "kit_camping", headerName: "Kit camping", flex: 3, minWidth: 200, },
            { field: "extrat", headerName: "extrat", flex: 3, minWidth: 200, },
            { field: "locker_status", headerName: "Status casier", flex: 3, minWidth: 100, },
            { field: "charger_status", headerName: "Status chargeur", flex: 3, minWidth: 100, },
            { field: "client_name", headerName: "Nom", flex: 3, minWidth: 100 },
            { field: "client_last_name", headerName: "Prenom", flex: 3, minWidth: 100 },
            { field: "client_email", headerName: "email", flex: 3, minWidth: 230 },
            { field: "client_phone", headerName: "Téléphone", flex: 3, minWidth: 180 },
            { field: "status", headerName: "Status", flex: 3, minWidth: 80, },
            { field: "place", headerName: "Emplacement", flex: 3, minWidth: 180, },
            { field: "mot_de_pass", headerName: "Mot de passe", flex: 3, minWidth: 100, },
            { field: "door_no", headerName: "N* de Casier", flex: 3, minWidth: 100, },
           
          ]
        } />
      </div>
    </div>

  )

}

export default DialogReservation