import React from 'react';
import Drawer from './drawer';
import { useSelector, useDispatch } from 'react-redux';

// ** Reactstrap Imports
import { Button } from 'reactstrap'

import CreateAdminForm from './drawerBody/createAdminForm';
import ModifAdminForm from './drawerBody/modifierAdminForm';


const DrawerRight = () => {

    const dispatch = useDispatch();
    const drawer = useSelector((state) => state.paramsappReducer.drawer )

    const toggleSidebar = () => {
        dispatch({type: "setDrawer", payload: {
            active: false,
            view: null,
            value: null
          } 
        })
    }

    return (

        <Drawer
            size='lg'
            open={drawer.active}
            title={drawer.view === "Créer un Utilisateur" ? "Créer un Utilisateur" : "Modifier un Utilisateur" }
            headerClassName='mb-1'
            contentClassName='pt-0'
            toggleSidebar={toggleSidebar}
            // onClosed={handleSidebarClosed}
        >


            {drawer.view === "Créer un Utilisateur" && <CreateAdminForm modify={false} datas={drawer.value} />}
            {drawer.view === "Modifier un Utilisateur" && <ModifAdminForm modify={true} datas={drawer.value} />}


            <Button type="reset" color="secondary" className=" tw-mt-2" outline onClick={toggleSidebar}>
                Retour
            </Button>

        </Drawer>

    );
}

export default DrawerRight;
