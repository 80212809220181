import { useEffect, useState } from "react";

import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = ({ value, loading }) => {

  const [stats, setStats] = useState(null);
  const lockerList = ["xs", "s", "l", "m", "xl", "mb"]

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">CA Total </h1>
      </div>
      <div className="bottom">
        {
          loading ? (
            <div className="loading">Loading...</div>
          ) : (
            // <div className="featuredChart">
            //   {/* <CircularProgressbar value={100} text={"CA"} strokeWidth={2} /> */}
            // </div>
            <p className="amount">{value && value.totalIncome} € </p>
          )
        }

        <p className="title">Total des ventes par taille de casier (sans les frais de service)</p>
        {/* <p className="desc">
          Previous transactions processing. Last payments may not be included.
        </p> */}
        {
          loading ? (
            <div className="loading">Loading...</div>
          ) : (
            <div className="summary">
              {
                value && lockerList.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="itemTitle">{item.toUpperCase()} </div>
                    <div className="itemResult positive">
                      {/* <KeyboardArrowDownIcon fontSize="small" /> */}
                      <div className="resultAmount">{
                        value.serviceFeeByLocker[item] ? value.serviceFeeByLocker[item] : 0} €</div>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>

    </div>
  );
};

export default Featured;
