// import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Row, Col, CardTitle, Form, Label, Input, Button, FormFeedback } from 'reactstrap'
import { useDispatch } from "react-redux";
import InputPasswordToggle from '../../@core/components/input-password-toggle'
import BlankLayout from '../../@core/layouts/BlankLayout'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AuthApi } from "../../api/authApi";
import { useSignIn } from 'react-auth-kit'
import logo from '../../assets/logo.png';
import source from "../../assets/images/illustration/auth-register-illustration-light.png"


const schema = yup.object({
  email: yup.string().email('Entrez un mail valide').required('Entrez un email'),
  password: yup.string().required('Entrez un password')
}).required();


const Login = () => {

  const signIn = useSignIn()
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const { ref, ...emailfield } = register("email")

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);



  const handleClick = async (data) => {

    setLoading(true)
    setError(false)

    const res = await AuthApi.login(data)
    // console.log(res, "poiuytr")
    if (res.success) {

      if (
        signIn(
          {
            token: res.data.access_token,
            expiresIn: 60,
            tokenType: "Bearer",
            authState: {
              ...res.data.data,
              token: res.data.access_token
            }
            // refreshToken: res.data.refreshToken
          }
        )
      ) {
        setLoading(false)
        if (res.data.data.role === 'EM' || res.data.data.role === 'OG' || res.data.data.role === 'RMC') {
          navigate("/events");
        }
        else {
          navigate("/");
        }
      }

    }
    else {
      setLoading(false)
      setError(res.message)
    }

  };


  return (

    <BlankLayout>

      <div className='auth-wrapper auth-cover'>

        <Row className='auth-inner m-0'>

          <Col className='d-none d-lg-flex align-items-center p-5' lg='8' sm='12'>
            <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
              <img className='tw-w-[50%] tw-h-[80%] ' src={source} alt='Login Cover' object-fit="contain" />
            </div>
          </Col>

          <Col className='d-flex align-items-center auth-bg px-2 p-lg-5' lg='4' sm='12'>

            <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>


              <CardTitle tag='h2' className='fw-bold mb-1'>
                Bienvenue sur My Locker! 👋
              </CardTitle>

              <span className=" tw-text-red-400" >{error ? error : null}</span>

              <Form className='auth-login-form mt-2' onSubmit={handleSubmit(handleClick)} >

                <div className='mb-1'>
                  <Label className='form-label' for='login-email'>
                    Email
                  </Label>
                  <Input type='text' id='username' innerRef={ref} {...emailfield} invalid={errors?.email} name='email' placeholder='john@mylocker.com' />
                  {errors?.email &&
                    <FormFeedback>{errors.email?.message}</FormFeedback>
                  }
                </div>

                <Controller
                  control={control}
                  name="password"
                  render={({ field: { ref, ...fieldProps } }) => (

                    <div className='mb-1'>
                      <div className='d-flex justify-content-between'>
                        <Label className='form-label' for='login-password'>
                          Password
                        </Label>
                        <Link to='/ForgotPassword'>
                          <small>Mot de passe oublié?</small>
                        </Link>
                      </div>
                      <InputPasswordToggle innerRef={ref} fieldProps={fieldProps} invalid={errors?.password} className='input-group-merge' id='login-password' />
                      {errors?.password &&
                        <FormFeedback>{errors.password?.message}</FormFeedback>
                      }
                    </div>

                  )}
                />

                <Button disabled={loading} color='primary' block>
                  {loading ?
                    "Chargement..."
                    :
                    "Connexion"
                  }
                </Button>

              </Form>

            </Col>
          </Col>

        </Row>

      </div>

    </BlankLayout>

  );

};

export default Login;
