import produce from "immer"


const eventappState = {

  //ADMIN
  loadEvt: false,
  keywordSearchEvt: false,
  metaEvt: {
    limit: 25,
    current_items: 0,
    total_items: 0,
    offset: 1,
    current_page: 1,
    total_pages: 0
  },
  dataEvt: {
    data: []
  }

}

function eventappReducer(state = eventappState, action) {

  let nextState

  switch (action.type) {

    case 'SetLoadEvt':

        nextState = produce(state, draftState => {
          draftState.loadEvt = action.value
        })
  
        return nextState || state;

    case 'SetDataEvt':

        nextState = produce(state, draftState => {
          draftState.dataEvt.data = action.value.items
          draftState.metaEvt = action.value.meta
        })
  
        return nextState || state;

    case 'SetRowPerPageEvt':

      nextState = produce(state, draftState => {
        draftState.metaEvt.limit = action.value
      })

      return nextState || state;

    case 'SetKeywordSearchEvt':

      nextState = produce(state, draftState => {
        draftState.keywordSearchEvt = action.value
      })
  
      return nextState || state;

    case 'DelEvt':

      let indexAdminDel = state.dataEvt.data.findIndex(user => user.id === action.value)

      nextState = produce(state, draftState => {
        draftState.dataEvt.data.splice(indexAdminDel, 1)
      })
  
      return nextState || state;

    case 'AddEvt':

      nextState = produce(state, draftState => {
        draftState.dataEvt.data.push(action.value)
      })
  
      return nextState || state;
        
    default:

      return state
  }
}


export default eventappReducer;
