import "./sidebar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import React from "react";
import SideMenu from "../../utils/menuRoles";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { useAuthUser } from 'react-auth-kit'



const Sidebar = () => {

  const { dispatchdark } = useContext(DarkModeContext);
  const auth = useAuthUser();


  return (

    <div className="sidebar tw-overflow-y-scroll  ">

      <div className="top tw-h-[50px] tw-sticky tw-top-0 tw-border-b-[2px] tw-bg-white tw-flex tw-justify-center tw-items-center ">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Mylocker</span>
        </Link>
      </div>

      <div className="center ">

        <ul>

          {

            SideMenu.map((groupMenu, index) => (

              // eslint-disable-next-line no-unused-expressions
              groupMenu.access.includes(auth().role) ?

                <React.Fragment key={index}>

                  <p className="title">{groupMenu.group}</p>

                  {groupMenu.menu.map((menuItem, index2) => (

                    menuItem.access.includes(auth().role) ?

                      <Link key={index2} to={menuItem.to} style={{ textDecoration: "none" }}>
                        <li>
                          {menuItem.icon()}
                          <span className=" tw-mt-1" >{menuItem.menuTitle}</span>
                        </li>
                      </Link>

                      : null

                  ))}

                </React.Fragment>

                :

                null

            ))

          }


        </ul>

        {/* <div className="bottom">
          <div
            className="colorOption"
            onClick={() => dispatchdark({ type: "LIGHT" })}
          ></div>
          <div
            className="colorOption"
            onClick={() => dispatchdark({ type: "DARK" })}
          ></div>
        </div> */}

      </div>

    </div>
  );
};

export default Sidebar;
