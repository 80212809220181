import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';


const COLORS = ['#E7075B', '#7f032f', '#ce86a0'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {` ${(percent * 100).toFixed(0)}%`}
        </text>
    );
};


const Verticalchart = ({ data }) => {

    return (
        <div className="featured">
            <div className="top">
                <h1 className="title">Satisfaction des clients </h1>
            </div>
            <div className="bottom">
                {
                    data && (

                        <PieChart width={300} height={300}>
                            <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                startAngle={180}
                                endAngle={0}
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                dataLabel="name"
                            >
                                {data.map((entry, index) => (
                                    <>
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}



                                        />

                                    </>
                                ))}
                            </Pie>
                        </PieChart>

                    )
                }
            </div>
        </div>

    );
};

export default Verticalchart;