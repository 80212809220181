import {useState, useEffect} from "react"
import {useDispatch, useSelector, batch} from 'react-redux'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationManager } from 'react-notifications';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useAuthUser } from 'react-auth-kit'

import { AdminApi } from "../../../api/adminApi";
import Countdown from 'react-countdown';



const DeleteAdminDialog = ({data}) => {

    const auth = useAuthUser();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [load, setload] = useState(false);

    const handleClose = () => {
        dispatch({type: 'setDialog', payload: { active: false, view: null } })
    };

    
    const handleSubmit = async() => {
       
                const res = await AdminApi.deleteAdmin({
                    id: data.id,
                    token: auth().token
                })
        
                setload(false)

                if (res.success) 
                {

                    batch(() => {
                        dispatch({type: 'setDialog', payload: { active: false, view: null } })
                        dispatch({type: 'DelAdmin', value: data.id })
                    })

                    NotificationManager.success("Administrateur supprimé avec succès ", '', 3000)
                }
                else
                {
                    NotificationManager.error("Erreur lors de la suppression. Vérifier votre connexion puis réessayer", '', 3000)
                }

    };
    

    return (

        <>
            <DialogTitle sx={{backgroundColor: '#E7075B', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <span className="tw-font-bold tw-text-[15px] tw-text-white" >SUPPRIMER ADMINISTRATEUR</span>
            </DialogTitle>   

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="tw-text-md tw-mt-3" >
                        Êtes-vous sûr(e) de vouloir supprimer l'administrateur <span className=" tw-font-bold" >{data.first_name+' ' +data.last_name}</span> ? <br/>
                    </div>
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white" onClick={() => handleClose()}>NON</button>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]" onClick={() => handleSubmit()}>
                    {load ?
                        <CircularProgress size={16} sx={{color: 'white'}} />
                        :
                        "OUI"
                    }
                </button>
            </DialogActions> 

        </>

    );

}

export default DeleteAdminDialog;
