import { useState } from "react";
import { NotificationManager } from 'react-notifications';
import Datetime from "react-datetime";

import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { ConstructionOutlined } from "@mui/icons-material";
import { Label } from "reactstrap";


const NewPromo = ({ inputs, title }) => {
  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [load, setload] = useState(false);

  const eventData = useFetch("/events") || {};
  const events = eventData.data.map((event) => ({
    value: event.id,
    label: event.event,
  }));


  const porcentage = [
    { value: 5, label: "5%" }, { value: 10, label: "10%" }, { value: 15, label: "15%" }, { value: 20, label: "20%" },
    { value: 25, label: "25%" }, { value: 30, label: "30%" }, { value: 35, label: "35%" }, { value: 40, label: "40%" },
    { value: 45, label: "45%" }, { value: 50, label: "50%" }, { value: 55, label: "55%" }, { value: 60, label: "60%" },
    { value: 65, label: "65%" }, { value: 70, label: "70%" }, { value: 75, label: "75%" }, { value: 80, label: "80%" },
    { value: 85, label: "85%" }, { value: 90, label: "90%" }, { value: 95, label: "95%" }, { value: 100, label: "100%" }
  ]

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    setload(true);
    // checke if all information is provide?
    if (!info.event_id) {
      setload(false);
      NotificationManager.error('Veuillez selectionner un event', 'Error', 5000);
      return;
    }
    if (!info.generatedTime || !info.start_date || !info.end_date) {
      setload(false);
      NotificationManager.error('Veuillez renseigner tous les champs requis', 'Error', 5000);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/promo`, info);
      setload(false);
    } catch (err) {
      NotificationManager.error('Nous avons rencontré une erreur du serveur', 'Error', 5000);
    }
    NotificationManager.success('Codes promo créés avec success', 'Success', 5000);
    setInfo({});
    // reset form input to blanc?
    document.querySelectorAll("input").forEach((input) => (input.value = ""));
    document.querySelectorAll(".css-qc6sy-singleValue").forEach((div) => (div.innerHTML = "Select..."));

  };


  return (
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>Créer des codes promos</h1>
        </div>
        <div className="bottom">
          <form>
            {inputs.map((input) => (
              <>
                <Label key={input.id} className="tw-mt-3 tw-text-xs" >{input.label}</Label>
                <input
                  className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                  onChange={handleChange}
                  type={input.type}
                  placeholder={input.placeholder}
                  id={input.id}
                />
                <span className="error text-danger">{errors[input.id]}</span>
              </>
            ))}

            <Label className="tw-mt-3 tw-text-xs" >Choisir un pourcentage</Label>

            <Select options={porcentage} onChange={(e) => setInfo((prev) => ({ ...prev, discount: e.value }))} />

            <Label className="tw-mt-3 tw-text-xs" >Choisir un event</Label>

            <Select options={events} onChange={(e) => setInfo((prev) => ({ ...prev, event_id: e.value }))} />

            <Label className="tw-mt-3 tw-text-xs" >Date de début</Label>
            <Datetime
              value={info.start_date}
              placeholder="dd/mm/yyyy"
              onChange={(date) => setInfo((prev) => ({
                ...prev, start_date: date
              }))}
              minDate={new Date()}

            />
            <Label className="tw-mt-3 tw-text-xs" >Date de fin</Label>

            <Datetime
              value={info.end_date}
              placeholder="dd/mm/yyyy"
              onChange={(date) => setInfo((prev) => ({
                ...prev, end_date: date
              }))}
              minDate={info.start_date || new Date()}
            />

            <br />
            <br />
            <button disabled={load}
              className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[14px] tw-rounded-lg tw-h-[35px] tw-px-[15px] "
              type="submit"
              onClick={handleClick} >
              {load ?

                "Chargement ..."

                :

                "Générer le(s) code(s) promo"
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPromo;
