import { takeLatest, put, call, all } from "redux-saga/effects"
import { CMSApi } from "../../api/cmsApi";
import {NotificationManager} from 'react-notifications';


function* getallfaq(payload) {

    yield all([
        put({ type: "SetLoadFaq", value: true }),
        put({type: "SetKeywordSearchFaq", value: false})
    ])

    const res = yield call(() =>
        CMSApi.getFAQ(payload.value)
    );

    // console.log(res);

    if (res.success) {

        yield all([
            put({type: "SetLoadFaq", value: false}),
            put({type: "SetDataFaq", value: {
                items: res.data,
                meta: {
                    limit: 25,
                    current_items: 0,
                    total_items: 25,
                    offset: 1,
                    current_page: 1,
                    total_pages: 1
                }
            }})
        ])

    }
    else {
        yield all([
            put({type: "SetLoadFaq", value: false})
        ])
        NotificationManager.error(`${res.message}`, '', 2500 );
    }

}


// function* getsearchfaq(payload) {

//     yield all([
//         put({type: "SetLoadAdmin", value: true}),
//         put({type: "SetKeywordSearchAdmin", value: payload.value.keyword.trim()})
//     ])

//     // const data = yield call(() =>
//     //     AlbumsApi.searchAlbums(payload.value)
//     // );

//     // // console.log(data);  

//     // if(data)
//     // yield all([
//     //     put({type: "SetLoadAdmin", value: false}),
//     //     put({type: "SetDataAdmin", value: data})
//     // ])
    

//     // yield all([
//     //     put({type: "SetLoadAdmin", value: false})
//     // ])

// }



export const faqappSaga = [
    takeLatest("GETALLFAQ", getallfaq),
    // takeLatest("GETSEARCHEFAQ", getsearchfaq),
]
