import "./list.scss"
// import Datatable from "../../components/datatable/Datatable"
import Datatable2 from "../../components/datatable/Datatable2"

const List2 = ({ columns }) => {
  return (
    <div className="list">
      {/* <Sidebar /> */}
      <div className="listContainer">
        {/* <Navbar /> */}
        <Datatable2 columns={columns} />
      </div>
    </div>
  )
}

export default List2