import axios from "axios";
const baseUrl = process.env.REACT_APP_PUBLIC_URL;


export const CMSApi = {

    async getCGU() {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/cgu',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async putCGU(content, content_english) {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/cgu',
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        cgu: content,
                        english_cgu: content_english
                    })
                    // credentials: 'include'
                }
            )
            const res = await response.json()
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getCGV() {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/cgv',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async putCGV(content, content_english) {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/cgv',
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        cgv: content,
                        english_cgv: content_english
                    })
                    // credentials: 'include'
                }
            )
            const res = await response.json()
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getRGPD() {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/rgdp',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async putRGPD(content, content_english) {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/rgdp',
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        rgdp: content,
                        english_rgdp: content_english
                    })
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getML() {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/terms',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async putML(content, content_english) {

        try {

            const response = await fetch(baseUrl + '/cms/legalterms/terms',
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        terms: content,
                        english_terms: content_english
                    })
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },


    async getFAQ(data) {

        try {

            const response = await fetch(baseUrl + '/cms/faqs',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async createFAQ(data) {

        try {

            const response = await fetch(baseUrl + '/cms/faqs',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async deleteFAQ(id) {

        try {

            const response = await fetch(baseUrl + `/cms/faqs/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
            )
            const res = await response.text()

            // console.log(res);

            // console.log(response.ok);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async putFAQ(data) {

        try {

            const response = await fetch(baseUrl + `/cms/faqs/${data.id}`,
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            question : data.question,
                            answer: data.answer,
                            english_question: data.english_question,
                            english_answer: data.english_answer
                        }
                    )
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getLink(data) {

        try {

            const response = await fetch(baseUrl + '/cms/links',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async putLink(data) {

        try {

            const response = await fetch(baseUrl + `/cms/links`,
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },


    async getAllJob(data) {

        try {

            const response = await fetch(baseUrl + '/cms/jobs',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async createJob(data) {

        try {

            const response = await fetch(baseUrl + '/cms/jobs',
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async putJob(data, id) {

        try {

            const response = await fetch(baseUrl + `/cms/jobs/${id}`,
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async deleteJob(id) {

        try {

            const response = await fetch(baseUrl + `/cms/jobs/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
            )
            const res = await response.text()

            // console.log(res);

            // console.log(response.ok);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },


    async getAllTesti(data) {

        try {

            const response = await fetch(baseUrl + '/cms/testimonies',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async createTesti(data) {

        // console.log(data);

        const formdata = new FormData();

        formdata.append("image", data.image );
        formdata.append("body", data.body );
        formdata.append("name", data.name );
        formdata.append("link", data.link);

        try {


            const response = await axios.post(baseUrl+'/cms/testimonies', formdata, {
                headers: {
                  "Content-Type": "multipart/form-data",
                }
            });

            return (
                {
                    success: true,
                    data: response.data
                }
            )
            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async deleteTesti(id) {

        try {

            const response = await fetch(baseUrl + `/cms/testimonies/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
            )
            const res = await response.text()

            // console.log(res);

            // console.log(response.ok);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async putTest(data, id) {

        try {

            const response = await fetch(baseUrl + `/cms/jobs/${id}`,
                {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },


    async getAllPatner(data) {

        try {

            const response = await fetch(baseUrl + '/cms/partners',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async createPatner(data) {

        // console.log(data);

        const formdata = new FormData();

        formdata.append("image", data.image );
        formdata.append("name", data.name );
        formdata.append("link", data.link);

        try {


            const response = await axios.post(baseUrl+'/cms/partners', formdata, {
                headers: {
                  "Content-Type": "multipart/form-data",
                }
            });

            return (
                {
                    success: true,
                    data: response.data
                }
            )
            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },
    async deletePatner(id) {

        try {

            const response = await fetch(baseUrl + `/cms/partners/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
            )
            const res = await response.text()

            // console.log(res);

            // console.log(response.ok);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },


    async getLockerStats(id) {

        try {

            const response = await fetch(baseUrl + `/kpi/eventLocker/${id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getEventStats(id) {

        try {

            const response = await fetch(baseUrl + `/kpi/eventStats/${id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },



    async getAllCandidat(id) {

        try {

            const response = await fetch(baseUrl + `/cms/candidats/job/${id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },



}