import { takeLatest, put, call, all } from "redux-saga/effects"
import {EventApi} from "../../api/eventApi"
import {NotificationManager} from 'react-notifications';


function* getallevents(payload) {

    yield all([
        put({ type: "SetLoadEvt", value: true }),
        put({type: "SetKeywordSearchEvt", value: false})
    ])

    const res = yield call(() =>
        EventApi.getAllEvent(payload.value)
    );

    // console.log(res);

    if (res.success) {

        yield all([
            put({type: "SetLoadEvt", value: false}),
            put({type: "SetDataEvt", value: {
                items: res.data,
                meta: {
                    limit: 25,
                    current_items: 0,
                    total_items: 25,
                    offset: 1,
                    current_page: 1,
                    total_pages: 1
                }
            }})
        ])

    }
    else {
        
        yield all([
            put({type: "SetLoadEvt", value: false})
        ])

        NotificationManager.error(`${res.message}`, '', 2500 );

    }


}


function* getsearchevents(payload) {

    yield all([
        put({type: "SetLoadAdmin", value: true}),
        put({type: "SetKeywordSearchAdmin", value: payload.value.keyword.trim()})
    ])

    // const data = yield call(() =>
    //     AlbumsApi.searchAlbums(payload.value)
    // );

    // // console.log(data);  

    // if(data)
    // yield all([
    //     put({type: "SetLoadAdmin", value: false}),
    //     put({type: "SetDataAdmin", value: data})
    // ])
    

    // yield all([
    //     put({type: "SetLoadAdmin", value: false})
    // ])

}



export const eventappSaga = [
    takeLatest("GETALLEVENTS", getallevents),
    takeLatest("GETSEARCHEVENTS", getsearchevents),
]
