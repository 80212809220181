const baseUrl = process.env.REACT_APP_PUBLIC_URL;


export const EventApi = {

    async getAllEvent(data) {

        try {

            const response = await fetch(baseUrl + '/events',
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(logdata)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async getAllReservation(id, token) {

        try {

            const response = await fetch(baseUrl + `/reservations/events/${id}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    
                }
            )
            const res = await response.json()

            // console.log(res);

            // console.log(response);
            
            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res   
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message   
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

    async deleteEvent(id, token) {

        try {

            const response = await fetch(baseUrl + `/events/${id}`,
                {
                    method: 'DELETE',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    }
                }
            )

            const res = await response.json();

            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res || null  
                    }
                );
            }
            else
            {
                
                return(
                    {
                        success: false,
                        message: res?.message || "Event cannot be deleted, already has reservations" 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );
        }
    },


    async createEvent(data, token) {

        try {

            const response = await fetch(baseUrl + `/admin`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify(data)
                    // credentials: 'include'
                }
            )
            const res = await response.json()

            // console.log(response);
            // console.log(res);

            if(response.ok)
            {            
                return(
                    {
                        success: true,
                        data: res.data || null  
                    }
                );
            }
            else
            {
                return(
                    {
                        success: false,
                        message: res?.message || null 
                    }
                );
            }

            
        } catch (error) {

            return(
                {
                    success: false,
                    message: "error"
                }
            );

        }


    },

}