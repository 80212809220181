export const userColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "user",
    headerName: "User",
    flex: 3, 
    minWidth: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img || "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.first_name}{' '} {params.row.last_name}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", flex: 3, minWidth: 200 },

  { field: "telephone", headerName: "Phone", flex: 3, minWidth: 200},
  { field: "role", headerName: "Role", flex: 2, minWidth: 100}
];

export const eventColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "logo",
    headerName: "Event",
    width: 270,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.logo ? `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` + params.row.logo : "https://i.ibb.co/MBtjqXQ/no-avatar.gif"} alt="avatar" />
          {params.row.event}
        </div>
      );
    },
  },
  { field: "type", headerName: "type", width: 100},
  { field: "country", headerName: "Pays", flex: 3, minWidth: 200},
  { field: "city", headerName: "Ville", flex: 3, minWidth: 200},
  { field: "organizer_name", headerName: "Organisateur", flex: 3, minWidth: 200},
  { field: "container_name", headerName: "Container attribué", flex: 3, minWidth: 200}
];

export const containerColumns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Nom du container", width: 270 },
  { field: "status", headerName: "Stauts du container", width: 200 },
  { field: "serial_nb", headerName: "numero de serie", width: 230,},
  { field: "type", headerName: "Type", width: 80,},
  {field: "connected", headerName: "connecté", width: 90,}
];

export const organisersColumns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "denomination", headerName: "Dénomination", width: 200 },
  { field: "address", headerName: "Adresse", width: 200 },
  { field: "email", headerName: "email", width: 150 },
  { field: "phone", headerName: "Téléphone", width: 150 },
  { field: "country", headerName: "Pays", width: 100,},
  { field: "container_name", headerName: "Container attribué", width: 150,},
  { field: "event", headerName: "Evénement", width: 150 },
];

export const promoColumns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "event_id", headerName: "Evenement", width: 200 },
  { field: "discount", headerName: "Réduction", width: 200 },
  { field: "used", headerName: "Status", width: 180},
  { field: "code", headerName: "Code", width: 180},
  { field: "start_date", headerName: "Date de début", width: 180},
  { field: "end_date", headerName: "Date de fin", width: 180},
];
