import "./navbar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuthUser } from 'react-auth-kit'
import { useSignOut } from 'react-auth-kit'

import { useNavigate } from "react-router-dom";

const Navbar = () => {

  const auth = useAuthUser();
  const signOut = useSignOut();

  const navigate = useNavigate();

  const handleLogout = async () => {
    signOut()
    // try {
    //   await axios.get("http://localhost:8080/api/v1/logout");
    //   console.log("logout");
    // } catch (err) { }
  }



  return (
    <div className="navbar">
      <div className="wrapper tw-justify-between">
        {/* <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div> */}
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
            {/* <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            /> */}

            <span className=" tw-font-semibold tw-ml-2" >
              Hello, {auth().last_name}
            </span>

          </div>

        </div>

        <div className=" tw-font-bold tw-flex tw-items-center " >

          {`Connecté en tant que ${auth().role}`}

          <button onClick={() => navigate('../../account-settings')} className=" tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] tw-bg-slate-500 " >
            <SettingsIcon className="icon" sx={{ marginRight: 1 }} />
            Settings
          </button>

          <button onClick={() => handleLogout()} className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] " >
            <ExitToAppIcon className="icon" sx={{ marginRight: 1 }} />
            Logout
          </button>

        </div>

      </div>
    </div>
  );
};

export default Navbar;
