import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { NotificationManager } from 'react-notifications';
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { EventApi } from '../../api/eventApi'
import { useAuthUser } from 'react-auth-kit'

const Datatablereserv = ({ id, columns }) => {

  const auth = useAuthUser();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllreservation = async () => {

    setLoading(true)
    const res = await EventApi.getAllReservation(id, auth().token)
    setLoading(false)

    if (res.success) {
      setList(res.data)
    }
    else {
      NotificationManager.error(`${res.message}`, '', 3000)
    }
  }

  useEffect(() => {
    getAllreservation();
  }, []);


  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={list}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        getRowId={() => uuidv4()}
      />
    </div>
  );

};

export default Datatablereserv;
