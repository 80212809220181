import React, { useState, useEffect, useCallback } from "react";
// import DatePicker from "react-datepicker";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import "./new.scss";
import CircularProgress from "@mui/material/CircularProgress";

import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import useFetch from "../../hooks/useFetch";
import { useLocation, useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import { Label } from "reactstrap";
import axios from "axios";

import { countries } from "./countryList";
import { useAuthUser } from "react-auth-kit";

const lockerType = ["xs", "s", "m", "l", "xl", "mb"];

const tarifType = [
  { value: "tarifPromo", label: "Tarifs réduit" },
  { value: "tarifNormal", label: "Tarifs normal" },
  { value: "tarifDM", label: "Tarifs Dernière minute" },
];
const lastServices = [];
const batteryType = [];

const campingType = [];

const extraType = [];

const EditEvent = ({ inputs, title }) => {
  const auth = useAuthUser();

  const dataform = new FormData();

  let { id } = useParams();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { data, loading, error } = useFetch(`/${path}/${id}`) || [];
  

  const [file, setFile] = useState(null);
  const [load, setload] = useState(false);
  const [image, setImage] = useState(true);
  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [place, setPlace] = useState({});

  const [batteryTypeInfo, setBatteryTypeInfo] = useState([]);
  const [extraTypeInfo, setExtraTypeInfo] = useState([]);
  const [campingTypeInfo, setCampingTypeInfo] = useState([]);
  const [stockLoaded, setStockLoaded] = useState(false);
  const [batteryLoaded, setBatteryLoaded] = useState(false);
  const [campingLoaded, setCampingLoaded] = useState(false);
  const [extraLoaded, setExtraLoaded] = useState(false);
  
  // -------------------- set tarif --------------------
  const [tarifPromo, setTarifPromo] = useState({});
  const [tarifNormal, setTarifNormal] = useState({});
  const [tarifDM, setTarifDM] = useState({});

  const [serviceType, setServiceType] = useState([]);
  const changeTarifPromo = (e, type) => {
    // if target value negative, set input to 0
    if (e.target.value < 0) {
      return (e.target.value = 0);
    }
    setTarifPromo((prev) => ({ ...prev, [type]: e.target.value }));
  };
  const changeServiceType = (e, type) => {
    setStockLoaded(true);
    if (e.target.value < 0) {
      return (e.target.value = 0);
    }
    type.qte = Number(e.target.value);
    //ajouter ou  remplacer le service type dans le state
    if(!serviceType.includes(type)){
      setServiceType([...serviceType, type])
    }else{
      setServiceType(serviceType.map((service) => service.id === type.id ? type : service))
    }
};

  const selectBatteryType = (e) => {
    setBatteryLoaded(true);
    
    for(const selected of batteryTypeInfo){
      //update battery type selected attribute
      if(e.find((item) => item.value === selected.value)){
        selected.selected = true
      }else{
        selected.selected = false
        selected.qte = 0
        //remove battery type from state if not selected
        setServiceType(serviceType.filter((service) => service.id !== selected.id))

        //update battery type info selected attribute
      }
      setBatteryTypeInfo(batteryTypeInfo.map((battery) => battery.id === selected.id ? selected : battery))
      
      
    }
    if(e.length > 0){
      setInfo((prev) => ({ ...prev, battery: true }))
    }
  };
  const selectCampingType = (e) => {
    setCampingLoaded(true);
    for(const selected of campingType){
      //update camping type selected attribute
      if(e.find((item) => item.value === selected.value)){
        selected.selected = true
      }else{
        selected.selected = false
        selected.qte = 0
        //remove camping type from state if not selected
        setServiceType(serviceType.filter((service) => service.id !== selected.id))
      }
      setCampingTypeInfo(campingTypeInfo.map((camping) => camping.id === selected.id ? selected : camping))
    }
    if(e.length > 0){
      setInfo((prev) => ({ ...prev, camping: true }))
    }
  };

  const selectExtraType = (e) => {
    setExtraLoaded(true);
    for(const selected of extraType){
      //update extra type selected attribute
      if(e.find((item) => item.value === selected.value)){
        selected.selected = true
      }else{
        selected.selected = false
        selected.qte = 0
        //remove extra type from state if not selected
        setServiceType(serviceType.filter((service) => service.id !== selected.id))
      }
      setExtraTypeInfo(extraTypeInfo.map((extra) => extra.id === selected.id ? selected : extra))
    }
    if(e.length > 0){
      setInfo((prev) => ({ ...prev, extra: true }))
    }
  };
  const changeTarifNormal = (e, type) => {
    // if target value negative, set input to 0
    if (e.target.value < 0) {
      return (e.target.value = 0);
    }
    setTarifNormal((prev) => ({ ...prev, [type]: e.target.value }));
  };

  const changeTarifDM = (e, type) => {
    // if target value negative, set input to 0
    if (e.target.value < 0) {
      return (e.target.value = 0);
    }
    setTarifDM((prev) => ({ ...prev, [type]: e.target.value }));
  };

  // -------------------- fin set tarif --------------------

  const containersData = useFetch("/containers") || {};
  const organizersData = useFetch("/organizers", auth().token) || {};
  const eventManagers = useFetch("/events/eventManagers") || {};
  const placedata = useFetch("/events/get/places") || [];
  const serviceTypes = useFetch("/services/all") || [];
  
  // map container free
  const freeContainer = containersData.data.filter(
    (container) => container.status === "Libre"
  );

  const containers = freeContainer.map((container) => ({
    value: container.id,
    label: container.name,
  }));

  const organizers = organizersData.data.map((organizer) => ({
    value: organizer.id,
    label: organizer.denomination,
  }));

  useEffect(() => {
    
    if (data.id) {
      setInfo(data);
      setPlace(data.place);
      setTarifPromo(data.tarif_reduit);
      setTarifNormal(data.tarif_normal);
      setTarifDM(data.tarif_dm);

     
    }
  }, [data]);
  useEffect(() => {
    if (!Array.isArray(data) && !stockLoaded) {
      if(data.servicesStock ) {
        Object.keys(data.servicesStock).map((key) => {
          
          Object.keys(data.servicesStock[key]).map((item) => {
            let qte = data.servicesStock[key][item].initialStock
            let service = data.servicesStock[key][item].serviceId
           
            if(key === "battery" && !batteryLoaded){
              batteryTypeInfo.map((type) => {
                if(type.id === service){
                  type.qte = qte
                  type.selected = true
                  if(!serviceType.includes(type)){
                    setServiceType([...serviceType, type])
                    //update battery type selected attribute
                    setBatteryTypeInfo(batteryTypeInfo.map((type) => type.id === service ? {...type, selected : true} : type))
                    
                  }
                }
              })
            }
            if(key === "camping" && !campingLoaded){
              campingTypeInfo.map((type) => {
                if(type.id === service){
                  type.qte = qte
                  type.selected = true
                  if(!serviceType.includes(type)){
                    setServiceType([...serviceType, type])
                    //update camping type selected attribute
                    setCampingTypeInfo(campingTypeInfo.map((type) => type.id === service ? {...type, selected : true} : type))
                    
                  }
                }
              })
            }
            if(key === "extra" && !extraLoaded){
              extraTypeInfo.map((type) => {
                if(type.id === service){
                  type.qte = qte
                  type.selected = true
                  if(!serviceType.includes(type)){
                    setServiceType([...serviceType, type])
                    //update extra type selected attribute
                    setExtraTypeInfo(extraTypeInfo.map((type) => type.id === service ? {...type, selected : true} : type))
                  }
                }
              })
            }
          })
        
        });
          
      }
    }
    if (!Array.isArray(serviceTypes)) {

      for (const serviceType of serviceTypes?.data) {
        if (serviceType?.name === "battery" && !batteryLoaded) {
          
            for (const battery of serviceType.service_types) {
              if(!batteryType.find((type) => type.id === battery.id))
                {
                  batteryType.push({
                    qte: 0,
                    label: battery.name,
                    id: battery.id,
                    value: battery.id,
                    selected : false
                  });
                }
              
            }
            setBatteryTypeInfo(batteryType);
        } else if (serviceType?.name === "camping" && !campingLoaded) {
          for (const camping of serviceType.service_types) {
            if(!campingType.find((type) => type.id === camping.id))
              {
                
                campingType.push({
                  qte: 0,
                  label: camping.name,
                  id: camping.id,
                  value: camping.id,
                  selectrd : false
                });
              }
            
          }
          setCampingTypeInfo(campingType);
         
        } else if (serviceType?.name === "extra" && !extraLoaded) {
          for (const extra of serviceType.service_types) {
            if(!extraType.find((type) => type.id === extra.id))
              {
                extraType.push({
                  qte: 0,
                  label: extra.name,
                  id: extra.id,
                  value: extra.id,
                  selected : false
                });
              }
          }
          setExtraTypeInfo(extraType);
        }
      };
    }
  },[serviceTypes]);

  const [placeList, setPlaceList] = useState([]);

  useEffect(() => {
    setPlaceList(placedata?.data);
  }, [placedata?.loading]);

  const locations = [
    { value: "Intérieur", label: "Intérieur" },
    { value: "Extérieur", label: "Extérieur" },
  ];

  const eventTypes = [
    { value: "FC", label: "Festivals - Concerts" },
    { value: "FS", label: "Foires - Salons" },
    { value: "PE", label: "Sports Mécaniques" },
    { value: "ES", label: "Événement Sportifs - Stades" },
    { value: "EC", label: "Événement Culturels - Salles de spectacles" },
    { value: "PA", label: "Plages - Parcs aquatiques" },
    { value: "PZ", label: "Parcs d'attractions - Zoo" },
    { value: "ST", label: "Sites touristique - Centres-Villes" },
  ];

  const handleCreate = async (inputValue) => {
    const myNewPlace = inputValue.split(",");
    // if myNewPlace exite in placelist, delete it
    const existPlace = placeList.find((item) => item.value === myNewPlace[0]);

    let newoptionsplace;

    if (existPlace) {
      // delete existPlace
      newoptionsplace = placeList.filter(
        (item) => item.value !== existPlace.value
      );

      // fetch api
      try {
        await axios.delete(
          `${process.env.REACT_APP_PUBLIC_URL}/events/places/${existPlace.value}`
        );
      } catch (err) {
        return NotificationManager.error(
          "Verifiez votre connexion internet !",
          "OUPS !",
          5000
        );
      }
    } else {
      const newOption = {
        value: myNewPlace[0],
        label: myNewPlace[0],
        place_en: myNewPlace[1] || myNewPlace[0],
      };

      newoptionsplace = [...placeList, newOption];

      // fetch api
      const info = {
        place_fr: myNewPlace[0],
        place_en: myNewPlace[1] || myNewPlace[0],
      };

      try {
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_URL}/events/places`,
          info
        );
      } catch (err) {
        return NotificationManager.error(
          "Verifiez votre connexion internet !",
          "OUPS !",
          5000
        );
      }
    }
    setPlaceList(newoptionsplace);
    return NotificationManager.success(
      "Les emplacements ont été bien mis à jour",
      "succès",
      5000
    );
  };

  // const handlePlace = (e) => {
  //   if (info.container_list) {
  //     const newplace = {}

  //     e.map((item) => {

  //       return newplace[item.value] = {
  //         "container_name": containers.find((c) => c.value === parseInt(info.container_list[0], 10)).label,
  //         "place_en": item.place_en
  //       }

  //     });

  //     setPlace(newplace)
  //   } else {
  //     NotificationManager.error('Veuillez choisir un container', 'Error', 5000);
  //     // reset select input "css-1s2u09g-control"
  //     document.querySelector(".css-1s2u09g-control").div.innerHTML = "";
  //   }
  // }

  const handleSetPlace = (e, container_name, index, item) => {
    let myPlaces = place;
    let seen = new Set();
    let filtered = {};
    for (let key in myPlaces) {
      if (seen.has(myPlaces[key].container_name)) {
        continue;
      } else {
        seen.add(myPlaces[key].container_name);
        filtered[key] = myPlaces[key];
      }
      setPlace(filtered);
    }
    myPlaces = filtered;

    let container;
    if (Object.keys(myPlaces).length === info?.container_list?.length) {
      container = myPlaces[Object.keys(myPlaces)[index]];
      delete myPlaces[Object.keys(myPlaces)[index]];
    } else {
      container = {
        container_id: item,
        container_name: container_name,
        place_en: e.place_en,
      };
    }

    myPlaces[e.value] = container;

    setPlace((prev) => ({
      ...prev,
      [e.value]: container,
    }));
  };

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));

    const { id, value } = e.target;
    switch (id) {
      case "event":
        setErrors((prev) => ({
          ...prev,
          event:
            value.length < 1
              ? "Le nom de l'événement doit contenir au moins 1 caractères"
              : "",
        }));
        break;
      case "type":
        setErrors((prev) => ({
          ...prev,
          type:
            value.length < 1
              ? "Le type de l'événement doit contenir au moins 1 caractères"
              : "",
        }));
        break;
      case "intallation_type":
        setErrors((prev) => ({
          ...prev,
          intallation_type:
            value.length < 1
              ? "Le type d'installation doit contenir au moins 1 caractères"
              : "",
        }));
        break;
      case "country":
        setErrors((prev) => ({
          ...prev,
          country:
            value.length < 1
              ? "Veuillez renseigner le pays de l'événement"
              : "",
        }));
        break;
      case "city":
        setErrors((prev) => ({
          ...prev,
          city:
            value.length < 1
              ? "Veuillez renseigner la ville de l'événement"
              : "",
        }));
        break;
      default:
        break;
    }
  };

  const handleContainer = (e) => {
    setInfo((prev) => ({
      ...prev,
      container_list: e.map((item) => item.value),
    }));

    if (Object.keys(place).length > e.length) {
      const containers_ids = e.map((item) => item.value);
      const newPlace = {};

      Object.keys(place).map((item) => {
        if (containers_ids.includes(place[item].container_id)) {
          newPlace[item] = place[item];
        }
      });

      setPlace(newPlace);
    }

    if (e.length === 1) {
      setPlace({});
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    // check if all requierd
    setload(true);

    if (!info.tarif_list || info.tarif_list.length === 0) {
      setload(false);
      return NotificationManager.error(
        "Veuillez ajouter au moins une tarification",
        "Error",
        5000
      );
    }

    if (!info.event) {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner le nom",
        "Error",
        5000
      );
    }
    if (!info.type) {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner le type",
        "Error",
        5000
      );
    }
    if (info.intallation_type === "") {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner le type d'installation",
        "Error",
        5000
      );
    }
    if (!info.country) {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner le pays",
        "Error",
        5000
      );
    }
    if (!info.city) {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner la ville",
        "Error",
        5000
      );
    }
    if (info.free === "") {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner le nombre de places",
        "Error",
        5000
      );
    }

    if (!info.start_date || !info.end_date) {
      setload(false);
      return NotificationManager.error(
        "Veuillez renseigner les dates de debut et de fin",
        "Error",
        5000
      );
    }

    // if multiple container_list, check if place has same npmber of key
    if (info.container_list.length > 1) {
      if (Object.keys(place).length !== info.container_list.length) {
        setload(false);
        return NotificationManager.error(
          "Veuillez renseigner les lieux",
          "Error",
          5000
        );
      }
    }

    if (!info.organizer_id) {
      setload(false);
      return NotificationManager.error(
        "Veuillez choisir un organisateur",
        "Error",
        5000
      );
    }

    info["container_id"] = info.container_list[0];
    info["place"] = JSON.stringify(place);

    Object.keys(info).forEach((key) => {
      if (key === "start_date" || key === "end_date") {
        dataform.append(key, new Date(info[key]).toISOString());
      } else if (key === "container_list") {
        dataform.append(key, JSON.stringify(info[key]));
      } else if (key === "tarif_list") {
        info.tarif_list.forEach((tarif) => {
          dataform.append("tarif_list[]", tarif);
        });
      } else if (
        key !== "tarif_normal" &&
        key !== "tarif_reduit" &&
        key !== "tarif_dm" &&
        info[key] !== null
      ) {
        dataform.append(key, info[key]);
      }
    });
    serviceType.forEach((service) => {
      service.qte = Number(service.qte);
    })
    dataform.append("services", JSON.stringify(serviceType));
    if (info.tarif_list.includes("tarifPromo")) {
      dataform.append("tarif_reduit", JSON.stringify(tarifPromo));
    }

    if (info.tarif_list.includes("tarifNormal")) {
      dataform.append("tarif_normal", JSON.stringify(tarifNormal));
    }

    if (info.tarif_list.includes("tarifDM")) {
      dataform.append("tarif_dm", JSON.stringify(tarifDM));
    }

    if (file) {
      dataform.append("logo", file);
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`,
        dataform,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      setload(false);
      return NotificationManager.error(
        "Nous avons rencontré une erreur du serveur",
        "Error",
        5000
      );
    }

    setload(false);
    return NotificationManager.success(
      "Evénement modifié avec succès",
      "succès",
      5000
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    // add file in fonrData
    setFile(acceptedFiles[0]);
    setImage(true);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  if (loading) {
    return (
      <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  if (info === "loading") {
    return (
      <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>Modifier l'évènement</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <Label className="tw-mt-3 tw-text-xs">Téléverser une image *</Label>
            <div
              {...getRootProps({
                className:
                  "dropzone tw-bg-[#E8F0FE] tw-flex justify-content-center tw-border tw-rounded-[50px] tw-h-[360px] tw-w-[300]",
              })}
            >
              <input {...getInputProps()} />
              <div className="d-flex align-items-center justify-content-center flex-column">
                {image ? (
                  <section className="dropzone tw-bg-[#E8F0FE] tw-flex justify-content-center tw-border tw-rounded-[50px] tw-h-[360px] tw-w-[300px]">
                    <img
                      className=""
                      src={
                        file
                          ? URL.createObjectURL(file)
                          : info.logo &&
                            `${process.env.REACT_APP_PUBLIC_URL}/events/logo/${info.logo}`
                      }
                      alt="slider logo"
                    />
                  </section>
                ) : (
                  <section>
                    {/* <FiDownloadCloud size={64} /> */}
                    <DriveFolderUploadOutlinedIcon />
                    <span className=" tw-text-[12px] ">
                      Glisser déposer ici l'image
                    </span>
                    <p className="text-secondary tw-text-[12px]">
                      Or cliquer ici pour{" "}
                      <a href="/" onClick={(e) => e.preventDefault()}>
                        téléverser
                      </a>{" "}
                      depuis votre machine
                    </p>
                  </section>
                )}
              </div>
            </div>
          </div>
          <div className="right">
            <form>
              <Label className="tw-mt-3 tw-text-xs">Nom de L'evenement *</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Nom de L'evenement"
                id="event"
                value={info?.event}
              />

              <Label className="tw-mt-3 tw-text-xs">Choisir le pays *</Label>
              <Select
                options={countries}
                value={
                  info.country &&
                  countries.find((c) => c.code === info.country.split(",")[0])
                }
                onChange={(e) =>
                  setInfo((prev) => ({
                    ...prev,
                    country: `${e.code},${e.label}`,
                  }))
                }
              />

              <Label className="tw-mt-3 tw-text-xs">
                Saisir la ville de l'evenement
              </Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Saisir la ville de L'evenement"
                id="city"
                value={info?.city}
              />

              <Label className="tw-mt-3 tw-text-xs">
                Saisir le departement de l'evenement
              </Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Saisir la ville de L'evenement"
                id="region"
                value={info?.region}
              />

              <Label className="tw-mt-3 tw-text-xs">Date de début *</Label>
              <Datetime
                value={new Date(info.start_date)}
                utc={true}
                placeholder="dd/mm/yyyy"
                locale="fr"
                onChange={(date) =>
                  setInfo((prev) => ({
                    ...prev,
                    start_date: date._d,
                  }))
                }
              />

              <Label className="tw-mt-3 tw-text-xs">Date de fin *</Label>
              <Datetime
                value={new Date(info.end_date)}
                utc={true}
                placeholder="dd/mm/yyyy"
                onChange={(date) =>
                  setInfo((prev) => ({
                    ...prev,
                    end_date: date._d,
                  }))
                }
              />

              <Label className="tw-mt-3 tw-text-xs">
                Choisir un organisateur *
              </Label>
              <Select
                options={organizers}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, organizer_id: e.value }))
                }
                value={organizers.find(
                  (o) => o.value === parseInt(info?.organizer_id)
                )}
              />

              <Label className="tw-mt-3 tw-text-xs">
                Choisir un event manager
              </Label>
              <Select
                options={eventManagers?.data}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, event_manager_id: e.value }))
                }
                value={eventManagers?.data.find(
                  (o) => o.value === parseInt(info?.event_manager_id)
                )}
              />

              <Label className="tw-mt-3 tw-text-xs">
                Intérieur ou Extérieur ?*
              </Label>
              <Select
                options={locations}
                name="location"
                classNamePrefix="select"
                // add to container list
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, location: e.value }))
                }
                value={{ value: info?.location, label: info?.location }}
              />

              <Label className="tw-mt-3 tw-text-xs">
                Choix du/des Conteneur(s) *
              </Label>
              <Select
                options={containers}
                isMulti
                name="container"
                className="basic-multi-select"
                classNamePrefix="select"
                // add to container list
                onChange={(e) => handleContainer(e)}
                value={
                  info.container_list && [
                    ...containers.filter((c) =>
                      info.container_list.includes(c.value)
                    ),
                  ]
                }
              />

              {info.container_list && info.container_list.length > 1 && (
                <section>
                  <Label className="tw-mt-3 tw-text-xs">
                    Choisir les emplacements *
                  </Label>
                  {
                    // choose in placelist
                    info.container_list.map((item, index) => (
                      <div key={item}>
                        <Label className="tw-mt-3 tw-text-xs">
                          {
                            containers.find(
                              (c) => c.value === parseInt(item, 10)
                            )?.label
                          }
                        </Label>

                        <CreatableSelect
                          name="place"
                          value={
                            Object.keys(place)[index] &&
                            place &&
                            placeList && [
                              ...placeList.filter(
                                (p) => p.value === Object.keys(place)[index]
                              ),
                            ]
                          }
                          onChange={(e) =>
                            handleSetPlace(
                              e,
                              containers.find(
                                (c) => c.value === parseInt(item, 10)
                              ).label,
                              index,
                              item
                            )
                          }
                          onCreateOption={handleCreate} // add this prop
                          isValidNewOption={(inputValue) =>
                            inputValue.trim().length > 0
                          } // add this prop
                          formatCreateLabel={(inputValue) =>
                            placeList.find((item) => item.value === inputValue)
                              ? `Supprimer l'emplacement "${inputValue}"`
                              : `Ajouter l'emplacement "${inputValue}"`
                          }
                          options={placeList}
                        />
                      </div>
                    ))
                  }
                </section>
              )}

              <Label className="tw-mt-3">
                Choisir le type de l'événement *
              </Label>
              <Select
                options={eventTypes}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, type: e.value }))
                }
                value={eventTypes.find((e) => e.value === info?.type)}
              />

              <Label className="tw-mt-3">
                Installation supérieur à 15 jours ? *
              </Label>
              <Select
                value={{
                  value: info.intallation_type,
                  label: info.intallation_type ? "Oui" : "Non",
                }}
                options={[
                  { value: true, label: "Oui" },
                  { value: false, label: "Non" },
                ]}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, intallation_type: e.value }))
                }
              />

              <Label className="tw-mt-3">Événement gratuit ? *</Label>
              <Select
                value={{ value: info.free, label: info.free ? "Oui" : "Non" }}
                options={[
                  { value: true, label: "Oui" },
                  { value: false, label: "Non" },
                ]}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, free: e.value }))
                }
              />

              <Label className="tw-mt-3">Site web de l'evenement</Label>
              <input
                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                onChange={handleChange}
                type="text"
                placeholder="Site web de l'evenement"
                id="web_site"
                value={info?.web_site}
              />

              {/* ---------------------- ajouter les tarifications ---------------------- */}

              <hr />

              <h3>Definir les tarifs des casiers </h3>
              <br />
              <Label className="tw-mt-3">Choix des tarifications *</Label>
              <Select
                options={tarifType}
                isMulti
                name="container"
                className="basic-multi-select"
                classNamePrefix="select"
                // add to  tarifList
                onChange={(e) =>
                  setInfo((prev) => ({
                    ...prev,
                    tarif_list: e.map((item) => item.value),
                  }))
                }
                value={
                  info.tarif_list && [
                    ...tarifType.filter((c) =>
                      info.tarif_list.includes(c.value)
                    ),
                  ]
                }
              />

              <h3 className="mt-2">Définir les services associés </h3>
              <br />

              <br />
              <Label className="mt-2">Ajouter les batteries ? *</Label>
              <Select
                options={batteryTypeInfo}
                isMulti
                name="batteryType"
                className="basic-multi-select"
                classNamePrefix="select"
                // add to  tarifList
                onChange={(e) =>selectBatteryType(e)}
                value={batteryTypeInfo.filter((type) => type.selected)}
              />

              {info?.battery && (
                <div className="tw-my-4">
                  <div className="stocks-locker">
                    {batteryType.map((type, id) => {
                      if(type.selected){
                        return (
                          <div key={id} className="tw-flex tw-flex-col">
                          <Label key={id} className="tw-mt-3 tw-text-xs">
                            {type?.label}{" "}
                          </Label>
                          <input
                            className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                            type="number"
                            onChange={(e) => changeServiceType(e, type)}
                            value={
                              serviceType.find((s) => s.id === type.id)?.qte ?? ''
                            }
                          />
                        </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}

              <Label className="mt-2">Ajouter les campings ? *</Label>

              <Select
                options={campingTypeInfo}
                isMulti
                name="batteryType"
                className="basic-multi-select"
                classNamePrefix="select"
                // add to  tarifList
                onChange={(e) =>selectCampingType(e)}
                value={campingTypeInfo.filter((type) => type.selected)}
                
              />


              {info?.camping && (
                <div className="">
                  <div className="stocks-locker">
                    {campingType.map((type, id) => 
                      {if(type.selected){
                        return (
                          
                            <div key={id} className="tw-flex tw-flex-col tw-w-[25%]">
                              <Label key={id} className="tw-mt-3 tw-text-xs">
                                {type?.label}{" "}
                              </Label>
                              <input
                                className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                                type="number"
                                onChange={(e) => changeServiceType(e, type)}
                                value={
                                  serviceType.find((s) => s.id === type.id)?.qte ?? ''
                                }
                              />
                            </div>
                          
                        );
                      }}
                    )}
                  </div>
                
              

              <Label className="mt-2">Ajouter les extras ? *</Label>
              <Select
                options={extraTypeInfo}
                isMulti
                name="extraType"
                className="basic-multi-select"
                classNamePrefix="select"
                // add to  tarifList
                onChange={(e) =>selectExtraType(e)}
                value={extraTypeInfo.filter((type) => type.selected)}
                
              />
                  <div className="tw-grid md:tw-grid-cols-3 tw-gap-4">
                    {extraType.map((type, id) => {
                      if(type.selected){
                        return (
                          <div key={id}>
                            <Label key={id} className="tw-mt-3 tw-text-xs">
                              {type?.label}{" "}
                            </Label>
                            <input
                              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                              type="number"
                              onChange={(e) => changeServiceType(e, type)}
                              value={
                                serviceType.find((s) => s.id === type.id)?.qte ?? ''
                              }
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}

              <Label className="mt-2">Ajouter les green CAB ? *</Label>

              <Select
                options={[
                  { value: true, label: "Oui" },
                  { value: false, label: "Non" },
                ]}
                value={{
                  value: info.green_cab,
                  label: info.green_cab ? "Oui" : "Non",
                }}
                className=""
                // add to container list
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, green_cab: e.value }))
                }
              />

              <br />
              <br />

              {info.tarif_list && info.tarif_list.length > 0 && (
                <>
                  {info.tarif_list.includes("tarifPromo") && (
                    <>
                      <h3>Tarifs réduit </h3>
                      <br />
                      <div className="stocks-locker">
                        {lockerType.map((type, id) => (
                          <>
                            <Label key={id} className="tw-mt-3 tw-text-xs">
                              {type === "mb" ? "Réfrigéré" : type.toUpperCase()}{" "}
                            </Label>
                            <input
                              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                              type="number"
                              onChange={(e) => changeTarifPromo(e, type)}
                              value={
                                tarifPromo && tarifPromo[type]
                                  ? `${tarifPromo[type]}`
                                  : ""
                              }
                            />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <br />
                  <br />

                  {info.tarif_list.includes("tarifNormal") && (
                    <>
                      <h3>Tarifs normal</h3>
                      <br />
                      <div className="stocks-locker">
                        {lockerType.map((type, id) => (
                          <>
                            <Label key={id} className="tw-mt-3 tw-text-xs">
                              {type === "mb" ? "Réfrigéré" : type.toUpperCase()}{" "}
                            </Label>
                            <input
                              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                              type="number"
                              onChange={(e) => changeTarifNormal(e, type)}
                              value={
                                tarifNormal && tarifNormal[type]
                                  ? `${tarifNormal[type]}`
                                  : ""
                              }
                            />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <br />
                  <br />

                  {info.tarif_list.includes("tarifDM") && (
                    <>
                      <h3>Tarifs Dernière minute</h3>
                      <br />
                      <div className="stocks-locker">
                        {lockerType.map((type, id) => (
                          <>
                            <Label key={id} className="tw-mt-3 tw-text-xs">
                              {type === "mb" ? "Réfrigéré" : type.toUpperCase()}{" "}
                            </Label>
                            <input
                              className="tw-h-[42px] tw-text-[13px]  tw-border tw-w-full tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                              type="number"
                              onChange={(e) => changeTarifDM(e, type)}
                              value={
                                tarifDM && tarifDM[type]
                                  ? `${tarifDM[type]}`
                                  : ""
                              }
                            />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}

              <br />

              <Label className="tw-my-3 tw-text-xs">Événement Fermé ?*</Label>
              <Select
                value={{
                  value: info.event_duration,
                  label:
                    info.event_duration === null || info.event_duration === 1
                      ? "Non"
                      : "Oui",
                }}
                options={[
                  { value: 0, label: "Oui" },
                  { value: 1, label: "Non" },
                ]}
                onChange={(e) =>
                  setInfo((prev) => ({ ...prev, event_duration: e.value }))
                }
              />

              {/* --------------------- fin ajout tarifications ---------------------------- */}

              <br />
              <br />
              <button
                disabled={load}
                type="submit"
                className="tw-text-sm tw-font-semibold tw-h-[35px] tw-mt-4 tw-px-3 tw-rounded-md tw-text-white btn-fushia"
                onClick={handleClick}
              >
                {load ? "Chargement ..." : "Modifier l'événement"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
