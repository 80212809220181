import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const Widget = ({ type, value, loading }) => {
  let data;

  //temporary
  const amount = value;
  // const diff = 20;

  switch (type) {
    case "Events":
      data = {
        title: "EVENEMENTS",
        isMoney: false,
        link: "Voir tous les évents",
        href: "/events",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "Réservations":
      data = {
        title: "RESERVATIONS",
        isMoney: false,
        link: "",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "CA today":
      data = {
        title: "CA AUJOURD'HUI",
        isMoney: true,
        link: "",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "Remboursements":
      data = {
        title: "REMBOURSEMENTS",
        isMoney: true,
        link: "",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      {
        loading ? (
          <div className="left">Loading...</div>
        ) : (
          <>
            <div className="left">
              <span className="title">{data.title}</span>
              <span className="counter">
                {amount} {data.isMoney && "€"}
              </span>
              <span className="link">
                <a href={data.href}>{data.link}</a>
              </span>
            </div>
            <div className="right">
              {/* <div className="percentage positive">
                <KeyboardArrowUpIcon />
                {diff} %
              </div> */}
              {data.icon}
            </div>
          </>
        )
      }
    </div>
  );
};

export default Widget;
