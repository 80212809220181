// ** React Imports
import { Row, Col, InputGroup, Input } from "reactstrap";
import Breadcrumbs from "../../@core/components/breadcrumbs";
import { Card, CardBody, Button } from 'reactstrap'
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { NotificationManager } from 'react-notifications';
import { CMSApi } from "../../api/cmsApi";
import './cms.scss';

const Links = () => {

  const [link, setLink] = useState([]);
  const [loading, setLoading ] = useState(false);
  const [load, setLoad ] = useState(false);

  const schema = yup.object({
    instagram: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    tiktok: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    facebook: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    whatsapp: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    youtube: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    linkedin: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    apple_link: yup.string().required('Entrez un lien').url("Entrez un url valide"),
    google_link: yup.string().required('Entrez un lien').url("Entrez un url valide")
  }).required();


  const { reset, control, handleSubmit, formState:{ errors, isDirty } } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema)
  });


  const getAllLink = async() => {

    setLoading(true)
    const res = await CMSApi.getLink()
    setLoading(false)

    if (res.success) 
    {

      reset(res.data.data)
      let array1 = [];

      for (const [key, value] of Object.entries(res.data.data)) {
        array1.push({
          [key]: value
        })
      }

      setLink(array1);

    }
    else
    {
      NotificationManager.error(`${res.message}`, '', 3000)
    }

  }

  useEffect(() => {
    getAllLink()
  }, []);

  const changeHandle = async (data) => {

    if(isDirty)
    {
      setLoad(true)
      const res = await CMSApi.putLink(data)
      setLoad(false)
  
      if (res.success) 
      {
  
        reset(res.data.data)
        NotificationManager.success('Liens mis à jour', '', 3000)

      }
      else
      {
        NotificationManager.error(`${res.message}`, '', 3000)
      }

    }else
    {
      NotificationManager.error('Aucune modification éffectuée', '', 3000)
    }

  }

  if(loading)
  {
      return(

          <div className=" tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center" >
              Loading...
          </div>

      )
  }

  return (

    <div className="datatable">

      <div className=" tw-w-full tw-my-3">

        <Breadcrumbs title="Liens Utiles" />

        <div className=" tw-w-full tw-h-[40px] tw-flex tw-mb-4 tw-justify-end tw-items-end " >

          <Button disabled={load} onClick={handleSubmit(changeHandle) }  type="submit" className="btn-fushia tw-justify-center tw-items-center tw-ml-3 tw-flex tw-flex-row tw-text-white tw-text-[12px] tw-rounded-lg tw-h-[35px] tw-px-[15px] " color="#ffffff">
            Modifier
          </Button>

        </div>

        <Row>

          {link.map((lnk, id) => (

            <Col key={id} lg="4" sm="6">

              <Card>

                <CardBody>

                  <div className="d-flex justify-content-between align-items-center">

                    <div className="tw-w-full ">

                      <h4>{Object.keys(lnk)[0]}</h4>

                      <InputGroup>
                        <Controller
                            name={Object.keys(lnk)[0]}
                            control={control}
                            render={({ field }) => (
                                <Input className="tw-mt-3 tw-w-full" invalid={errors?.[Object.keys(lnk)[0]] && true} {...field} placeholder='Question...' />
                            )}
                        />
                      </InputGroup>
                      
                      <span className=" tw-text-red-500 tw-mt-1 " >
                        {errors?.[Object.keys(lnk)[0]]?.message}
                      </span>

                    </div>

                    {/* <div
                      className={`avatar avatar-stats p-50 m-0 bg-light-success`}
                    >
                      <div className="avatar-content">
                        <Link size={21} />
                      </div>
                    </div> */}

                  </div>

                </CardBody>

              </Card>

            </Col>

          ))}

        </Row>
      </div>
    </div>

  );

};

export default Links;
