import {useState, useEffect} from "react"
import {useDispatch, useSelector, batch} from 'react-redux'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationManager } from 'react-notifications';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useAuthUser } from 'react-auth-kit'

import { AdminApi } from "../../../api/adminApi";
import { CMSApi } from "../../../api/cmsApi";
import Countdown from 'react-countdown';



const ModifTerms = ({data}) => {

    const auth = useAuthUser();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [load, setload] = useState(false);
   
    const handleClose = () => {
        dispatch({type: 'setDialog', payload: { active: false, view: null } })
    };

    
    const handleSubmit = async() => {

                setload(true)
                let res;

                if (data.subtitle === "CGU") {
                    res = await CMSApi.putCGU(data.content, data.content_english)
                }
                else if (data.subtitle === "CGV") {
                    res = await CMSApi.putCGV(data.content, data.content_english)
                }
                else if (data.subtitle === "RGPD") {
                    res = await CMSApi.putRGPD(data.content, data.content_english)
                }
                else if (data.subtitle === "ML") {
                    res = await CMSApi.putML(data.content, data.content_english) 
                }


                setload(false)

                if (res.success) 
                {

                    batch(() => {
                        dispatch({type: 'setDialog', payload: { active: false, view: null } })
                    })
                    navigate(-1)
                    NotificationManager.success(`${data.subtitle} modifié avec succès`, '', 3000)
                }
                else
                {
                    NotificationManager.error("Erreur lors de la modification . Vérifier votre connexion puis Réessayer", '', 3000)
                }

    };
    

    return (

        <>
            <DialogTitle sx={{backgroundColor: '#E7075B', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
                <span className="tw-font-bold tw-text-[15px] tw-text-white" >MODIFIER {data.subtitle}</span>
            </DialogTitle>   

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="tw-text-md tw-mt-3" >
                        Êtes-vous sûr(e) de vouloir modifier <span className=" tw-font-bold" >{data.title}</span> ? <br/>
                    </div>         

                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700" onClick={() => handleClose()}>NON</button>
                <button disabled={load} className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]" onClick={() => handleSubmit()}>
                    {load ?
                        <CircularProgress size={16} sx={{color: 'white'}} />
                        :
                        "OUI"
                    }
                </button>
            </DialogActions> 

        </>

    );

}

export default ModifTerms;
